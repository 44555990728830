import { Schema } from '../../../types';

export const clientGroupSearch = (
	value?: string
): Schema.ClientGroupWhereInput => {
	if (!value) return {};
	value = value.trim();

	if (value === '') return {};
	const valueAsNumber = Number(value);
	const isValueNaN = isNaN(valueAsNumber);

	const filters: Schema.ClientGroupWhereInput['OR'] = [
		{
			name: { contains: value }
		},
		{
			description: { contains: value }
		}
	];

	if (!isValueNaN) {
		filters.push({ id: { equals: valueAsNumber } });
	}

	return { OR: filters };
};
