import { useMemo, useState } from 'react';
import {
	BooleanField,
	DatagridConfigurable,
	DateField,
	ListContextProvider,
	ListView,
	NumberField,
	SelectColumnsButton,
	TextField,
	useGetMany,
	useList,
	Pagination
} from 'react-admin';
import { useQuery, UseQueryResult } from 'react-query';
import { ApolloError, gql } from '@apollo/react-hooks';
import {
	FormControlLabel,
	Switch,
	Stack,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import merge from 'lodash/merge';

import { apolloClient } from '../../apolloClient';
import { Schema } from '../../types';

type FilterToggleProps = {
	label: string;
	value: boolean;
	setValue: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterToggle: React.FC<FilterToggleProps> = ({
	label,
	value,
	setValue
}) => (
	<FormControlLabel
		label={label}
		control={
			<Switch
				checked={value}
				onChange={e => setValue(e.target.checked)}
			/>
		}
	/>
);

const DisconnectedClientDatagrid: React.FC = () => (
	<DatagridConfigurable
		bulkActionButtons={false}
		rowClick={id => `/ClientAuth/${id}`}
		omit={[
			'status',
			'client.subscription_id',
			'parent_identifier',
			'client_identifier',
			'client.install_complete'
		]}
	>
		<TextField source="id" />
		<NumberField label="Practice name" source="client.practice_name" />
		<DateField showTime source="last_auth" />
		<BooleanField label="Admin connected?" source="isAdminConnected" />
		<BooleanField label="Flex connected?" source="isFlexConnected" />
		<TextField source="version" />
		<TextField source="target_version" />
		<TextField source="client_identifier" />
		<TextField source="parent_identifier" />
		<BooleanField source="status" />
		<NumberField label="Subscription id" source="client.subscription_id" />
		<BooleanField
			looseValue
			label="Install complete?"
			source="client.install_complete"
		/>
	</DatagridConfigurable>
);

type DisconnectedClientsQueryResponse = {
	disconnectedClients: number[];
};

type Ids = {
	id: number;
}[];
type DisconnectedClientsQueryResult = UseQueryResult<Ids>;
type IdsResponse = Omit<DisconnectedClientsQueryResult, 'data'> & {
	ids: Exclude<DisconnectedClientsQueryResult['data'], undefined>;
};

type LastAuthFilterOperator = 'before' | 'after';

type GetIdsOptions = {
	includeInactive: boolean;
	includeInternal: boolean;
	includeUninstalled: boolean;
	filterLastAuth: boolean;
	lastAuthFilter: Date | null;
	lastAuthFilterOperator: LastAuthFilterOperator;
};

const filterInactiveArgs: Schema.ClientAuthWhereInput = {
	status: { equals: true }
};

const filterInternalArgs: Schema.ClientAuthWhereInput = {
	client: {
		is: {
			subscription_id: { gt: 0 }
		}
	}
};

const filterUninstalledArgs: Schema.ClientAuthWhereInput = {
	client: {
		is: {
			install_complete: { equals: true }
		}
	}
};

const useGetIds = ({
	includeInactive,
	includeInternal,
	includeUninstalled,
	filterLastAuth,
	lastAuthFilter,
	lastAuthFilterOperator
}: GetIdsOptions): IdsResponse => {
	const where = useMemo(() => {
		let where: Schema.ClientAuthWhereInput = {};

		if (!includeInactive) {
			where = merge(where, filterInactiveArgs);
		}

		if (!includeInternal) {
			where = merge(where, filterInternalArgs);
		}

		if (!includeUninstalled) {
			where = merge(where, filterUninstalledArgs);
		}

		if (filterLastAuth && lastAuthFilter !== null) {
			let filterLastAuthArgs: Schema.ClientAuthWhereInput;

			switch (lastAuthFilterOperator) {
				case 'before':
					filterLastAuthArgs = {
						last_auth: { lte: lastAuthFilter }
					};
					break;
				case 'after':
					filterLastAuthArgs = {
						last_auth: { gte: lastAuthFilter }
					};
					break;
			}

			where = merge(where, filterLastAuthArgs);
		}

		return where;
	}, [
		includeInactive,
		includeInternal,
		includeUninstalled,
		filterLastAuth,
		lastAuthFilter,
		lastAuthFilterOperator
	]);

	const { data, ...rest } = useQuery<Ids, ApolloError>({
		queryKey: [
			'DisconnectedClientIds',
			includeInactive,
			includeInternal,
			includeUninstalled,
			filterLastAuth,
			lastAuthFilter,
			lastAuthFilterOperator
		],
		async queryFn() {
			const { data, error } = await apolloClient.query<
				DisconnectedClientsQueryResponse,
				{ where?: Schema.ClientAuthWhereInput }
			>({
				query: gql`
					query DisconnectedClients($where: ClientAuthWhereInput) {
						disconnectedClients(where: $where)
					}
				`,
				variables: {
					where
				},
				fetchPolicy: 'no-cache'
			});

			if (error) throw error;

			return (data?.disconnectedClients ?? []).map(id => ({ id }));
		},
		refetchInterval: 5000,
		refetchIntervalInBackground: true
	});

	return { ids: data ?? [], ...rest };
};

export const DisconnectedClientList: React.FC = () => {
	const [includeInactive, setIncludeInactive] = useState(false);
	const [includeInternal, setIncludeInternal] = useState(false);
	const [includeUninstalled, setIncludeUninstalled] = useState(false);
	const [filterLastAuth, setFilterLastAuth] = useState(false);
	const [lastAuthFilterOperator, setLastAuthFilterOperator] =
		useState<LastAuthFilterOperator>('after');
	const [lastAuthFilter, setLastAuthFilter] = useState<Date | null>(null);

	const { ids, isLoading, error, ...rest } = useGetIds({
		includeInternal,
		includeInactive,
		includeUninstalled,
		filterLastAuth,
		lastAuthFilter,
		lastAuthFilterOperator
	});

	const idsListContext = useList({
		data: ids,
		perPage: 10,
		isLoading,
		error,
		...rest
	});

	const res = useGetMany(
		'DisconnectedClient',
		{
			ids: idsListContext.data.map(({ id }) => id)
		},
		{
			enabled: !isLoading
		}
	);

	const dataListContext = useList({
		...res,
		isLoading: res.isLoading ?? isLoading,
		error: res.error ?? error
	});

	return (
		<ListContextProvider value={dataListContext}>
			<Stack direction="row" sx={{ mb: 1 }} alignItems="flex-start">
				<FilterToggle
					label="Include inactive"
					value={includeInactive}
					setValue={setIncludeInactive}
				/>

				<FilterToggle
					label="Include internal"
					value={includeInternal}
					setValue={setIncludeInternal}
				/>

				<FilterToggle
					label="Include uninstalled"
					value={includeUninstalled}
					setValue={setIncludeUninstalled}
				/>

				<Stack direction="column" spacing={0}>
					<FilterToggle
						label="Filter by last auth time"
						value={filterLastAuth}
						setValue={setFilterLastAuth}
					/>

					{filterLastAuth && (
						<Stack
							direction="row"
							spacing={1}
							flexWrap="wrap"
							alignItems="flex-end"
						>
							<ToggleButtonGroup
								exclusive
								size="small"
								value={lastAuthFilterOperator}
								onChange={(_, newValue) =>
									setLastAuthFilterOperator(newValue)
								}
							>
								<ToggleButton value="before">
									Before
								</ToggleButton>

								<ToggleButton value="after">After</ToggleButton>
							</ToggleButtonGroup>

							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DateTimePicker
									label="Last Auth"
									value={lastAuthFilter}
									onChange={newValue =>
										setLastAuthFilter(newValue)
									}
								/>
							</LocalizationProvider>
						</Stack>
					)}
				</Stack>

				<SelectColumnsButton style={{ marginLeft: 'auto' }} />
			</Stack>

			<ListView
				title="Disconnected Clients"
				// exporter={false}
				hasCreate={false}
				actions={false}
				pagination={
					<ListContextProvider value={idsListContext}>
						<Pagination />
					</ListContextProvider>
				}
				empty={false}
			>
				<DisconnectedClientDatagrid />
			</ListView>
		</ListContextProvider>
	);
};
