import { useCallback, useMemo, useState } from 'react';
import { Alert, Box, DialogContentText, TextField } from '@mui/material';
import { useGetRecordId, useRecordContext, useRefresh } from 'react-admin';

import {
	DialogButton,
	DialogButtonProps
} from '../../../../../components/DialogButton';
import { Schema } from '../../../../../types';
import FlexPackageTypeSelect from './InstallCompleteButton/FlexPackageTypeSelect';
import { FlexPackage } from '../../../../../generated/graphql/types/schema';

export type InstallCompleteButtonProps = DialogButtonProps['buttonProps'] & {
	disabled?: boolean;
};

const urlRegex = /[A-Za-z0-9-_]+/;

type Validation = {
	error: boolean;
	errors: string[];
};

const validateURL = (value: string): Validation => {
	if (value === '') {
		return {
			error: true,
			errors: ['Please enter a URL.']
		};
	}
	const errors: string[] = [];

	if (!value.match(urlRegex)) {
		errors.push(
			'Please enter a valid URL. Can only contain letters, numbers, - and _.'
		);
	}

	if (value.length < 2) {
		errors.push('URL must be at least three characters in length.');
	}

	if (value.length > 25) {
		errors.push('URL must be less than twenty six characters in length.');
	}

	return {
		error: errors.length > 0,
		errors
	};
};

export const InstallCompleteButton: React.FC<InstallCompleteButtonProps> = ({
	disabled = true,
	...props
}) => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const refresh = useRefresh();
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [touched, setTouched] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [value, setValue] = useState<string>('');
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState(false);
	const [installedFlexPackage, setInstalledFlexPackage] =
		useState<FlexPackage>('FLEX');
	const validation = useMemo(() => validateURL(value), [value]);
	const validationError = useMemo(
		() => touched && dirty && validation.error,
		[touched, dirty, validation.error]
	);

	const reset = useCallback(() => {
		setLoading(false);
		setValue('');
		setError(undefined);
		setTouched(false);
		setDirty(false);
		setIsOpen(false);
		refresh();
	}, [refresh]);

	const handleSave = useCallback(async () => {
		if (validation.error || record?.client.subscription_id === undefined)
			return;

		try {
			setLoading(true);
			const res = await fetch('/secure/installcomplete', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify({
					clientId: id,
					url: value,
					installedFlexPackage,
					subscriptionId: record.client.subscription_id
				})
			});

			if (!res.ok) {
				throw new Error(`Error: ${res.status} ${res.statusText}`);
			}

			if (res.status === 201) {
				alert(
					'Install completed, but we were unable to send an email to this client. Please send one manually.'
				);
			}

			setSuccess(true);
			reset();
		} catch (err) {
			let message = String(err);
			if (err instanceof Error) message = err.message;

			setLoading(false);
			setError(message);
		}
	}, [
		validation.error,
		record?.client.subscription_id,
		id,
		value,
		installedFlexPackage,
		reset
	]);

	return (
		<DialogButton
			isOpen={isOpen}
			handleClose={reset}
			handleSave={handleSave}
			setIsOpen={setIsOpen}
			canSave={
				!!record?.client.subscription_id &&
				!validation.error &&
				!loading
			}
			label="Install Complete"
			buttonProps={props}
			dialogProps={{ maxWidth: 'sm' }}
			success={success}
			setSuccess={setSuccess}
			successMessage="Install completed successfully."
			disabled={disabled}
		>
			{!record?.client.subscription_id && (
				<Alert severity="error">
					Client must have a subscription id before their installation
					can be marked as complete.
				</Alert>
			)}

			<DialogContentText>
				You are about to complete this office's installation. Please
				enter the URL you generated for them.
			</DialogContentText>
			<Box sx={{ my: 1 }}>
				<FlexPackageTypeSelect
					onChange={selectedPackage =>
						setInstalledFlexPackage(selectedPackage)
					}
				/>
			</Box>
			<TextField
				variant="outlined"
				required
				disabled={!record?.client.subscription_id}
				fullWidth
				label="Flex URL (just suffix, not full URL)"
				error={validationError}
				value={value || ''}
				onBlur={() => {
					if (!touched && dirty) setTouched(true);
				}}
				onChange={e => {
					const newValue = e.target.value;
					setValue(newValue);
					if (!dirty) setDirty(true);
					if (!touched && dirty) setTouched(true);
				}}
				helperText={
					validationError ? (
						<>
							{validation.errors.map(error => (
								<div>{error}</div>
							))}
						</>
					) : (
						''
					)
				}
			/>
			{error && <Alert severity="error">{error}</Alert>}
		</DialogButton>
	);
};
