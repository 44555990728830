import {
	BooleanInput,
	FilterButton,
	List,
	SelectColumnsButton,
	TextInput,
	TopToolbar
} from 'react-admin';

import { QuickFilter } from '../../components';
import { ClientAuthDatagrid } from './components/ClientAuthDatagrid';

const clientAuthFilters = [
	<TextInput label="Search" source="clientAuth_search" alwaysOn />,
	<TextInput label="Subscription ID" source="clientAuth_subscriptionid" />,
	<QuickFilter source="status" label="Active" defaultValue="1" />,
	<BooleanInput
		source="clientAuth_install_complete"
		label="Install Complete"
		defaultValue={false}
		sx={{
			alignSelf: 'center',
			mb: '8px',
			ml: 1
		}}
	/>
];

const ClientAuthListActions: React.FC = () => (
	<TopToolbar>
		<FilterButton />
		<SelectColumnsButton />
	</TopToolbar>
);

export const ClientAuthList: React.FC = () => (
	<List
		filters={clientAuthFilters}
		exporter={false}
		actions={<ClientAuthListActions />}
	>
		<ClientAuthDatagrid />
	</List>
);
