import { useCallback, useMemo, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { useRecordContext, useRefresh } from 'react-admin';

import {
	DialogButton,
	DialogButtonProps
} from '../../../../../components/DialogButton';
import { Schema } from '../../../../../types';

export type ChangeSMSNumberButtonProps = DialogButtonProps['buttonProps'] & {
	disabled?: boolean;
};

type Validation = {
	error: boolean;
	errors: string[];
};

const validateSMSNumber = (value?: string): Validation => {
	if (!value) {
		return {
			error: true,
			errors: ['Please enter a new SMS number.']
		};
	}
	const errors: string[] = [];

	const valueAsNumber = Number(value);

	if (isNaN(valueAsNumber)) {
		errors.push('The new number can only contain digits.');
	}

	if (value.length !== 11) {
		errors.push('The new number must be exactly 11 digits long.');
	}

	if (value.charAt(0) !== '1') {
		errors.push('The new number must start with "1".');
	}

	return {
		error: errors.length > 0,
		errors
	};
};

export const ChangeSMSNumberButton: React.FC<ChangeSMSNumberButtonProps> = ({
	disabled = true,
	...props
}) => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const refresh = useRefresh();
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [touched, setTouched] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [value, setValue] = useState<string>();
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState(false);
	const validation = useMemo(() => validateSMSNumber(value), [value]);
	const validationError = useMemo(
		() => touched && dirty && validation.error,
		[touched, dirty, validation.error]
	);

	const reset = useCallback(() => {
		setLoading(false);
		setValue(undefined);
		setError(undefined);
		setTouched(false);
		setDirty(false);
		setIsOpen(false);
		refresh();
	}, [refresh]);

	const handleSave = useCallback(
		async ({ endpoint = '/secure/changeSMS' }) => {
			if (validation.error || record?.sms?.smsnumber === undefined)
				return;

			try {
				const res = await fetch(endpoint, {
					method: 'POST',
					headers: {
						'content-type': 'application/json'
					},
					body: JSON.stringify({
						clientId: record.id,
						smsnumber: value,
						orignumber: record.sms.smsnumber
					})
				});

				if (!res.ok) {
					throw new Error(`Error: ${res.status} ${res.statusText}`);
				}

				setSuccess(true);
				reset();
			} catch (err) {
				let message = String(err);
				if (err instanceof Error) message = err.message;

				setLoading(false);
				setError(message);
			}
		},
		[validation.error, value, record?.id, record?.sms?.smsnumber, reset]
	);

	// Wrapper function for handleSave to match the expected type
	const handleSaveWrapper = useCallback(() => {
		handleSave({ endpoint: '/secure/changeSMS' });
	}, [handleSave]);

	const handleSpecialSave = useCallback(async () => {
		// TODO: Add confirmation alert to verify intent
		handleSave({ endpoint: '/secure/admin/queueSmsSwap' });
		// TODO: Add success and failure alerts?
	}, [handleSave]);

	return (
		<DialogButton
			isOpen={isOpen}
			handleClose={reset}
			handleSpecialSave={handleSpecialSave}
			handleSave={handleSaveWrapper}
			setIsOpen={setIsOpen}
			canSave={!validation.error && !loading}
			label={`Change SMS Number${record?.sms.secret ? '🕑' : ''}`}
			buttonProps={props}
			dialogProps={{ maxWidth: 'sm' }}
			success={success}
			setSuccess={setSuccess}
			successMessage="SMS number changed (or scheduled for change)."
			disabled={disabled}
		>
			<Alert severity="warning">
				{/* TODO: document what they are actually doing and what needs to be done first here */}
				ONLY PROCEED IF YOU KNOW WHAT YOU ARE DOING!!!
			</Alert>

			{record?.sms.secret && (
				<Alert sx={{ mt: 2 }} severity="info">
					SMS Swap already queued. New number:
					{record?.sms?.secret.split(',')[1]}
					<br></br>
					<i>
						Need to undo? Enter the client's current number then
						click schedule
					</i>
				</Alert>
			)}

			<TextField
				fullWidth
				label="New SMS Number"
				error={validationError}
				value={value}
				onBlur={() => {
					if (!touched && dirty) setTouched(true);
				}}
				onChange={e => {
					if (!dirty) setDirty(true);
					setValue(e.target.value);
				}}
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				helperText={
					validationError ? (
						<>
							{validation.errors.map(error => (
								<div>{error}</div>
							))}
						</>
					) : (
						''
					)
				}
			/>
			{/* TODO: Add warning message if an sms swap has already been queued for this client */}
			{error && <Alert severity="error">{error}</Alert>}
		</DialogButton>
	);
};
