import { Chip } from '@mui/material';
import { useTranslate } from 'react-admin';

export type QuickFilterProps = {
	label: string;
	source: string;
	defaultValue: string | number | boolean;
	alwaysOn?: boolean;
};

export const QuickFilter: React.FC<QuickFilterProps> = ({ label }) => {
	const translate = useTranslate();

	return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};
