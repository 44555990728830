import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle as MuiDialogTitle,
	IconButton,
	useMediaQuery,
	useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import { ButtonPropsNoClick } from '../types/types';
import { ResultSnackbar } from '.';

type DialogTitleProps = {
	children: React.ReactNode;
	handleClose: () => void;
};

const DialogTitle: React.FC<DialogTitleProps> = ({ children, handleClose }) => (
	<MuiDialogTitle sx={{ m: 0, p: 2 }}>
		{children}

		<IconButton
			onClick={handleClose}
			sx={{
				position: 'absolute',
				right: 8,
				top: 8,
				color: theme => theme.palette.grey[500]
			}}
		>
			<CloseIcon />
		</IconButton>
	</MuiDialogTitle>
);

export type DialogButtonProps = {
	label: string;
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onOpen?: () => unknown;
	handleClose: () => unknown;
	handleSave: () => unknown;
	handleSpecialSave?: () => unknown;
	canSave?: boolean;
	success?: boolean;
	setSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
	successMessage?: string;
	saveButtonText?: string;
	buttonProps?: ButtonPropsNoClick;
	dialogProps?: Omit<DialogProps, 'open'>;
	children: React.ReactNode;
	disabled?: boolean;
};

export const DialogButton: React.FC<DialogButtonProps> = ({
	label,
	isOpen,
	setIsOpen,
	onOpen,
	handleClose,
	handleSpecialSave,
	handleSave,
	canSave,
	buttonProps,
	dialogProps,
	children,
	success = false,
	setSuccess,
	successMessage = 'Success!',
	saveButtonText = 'Save',
	disabled = true
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<Dialog
				fullScreen={fullScreen}
				fullWidth
				maxWidth="md"
				open={isOpen}
				{...dialogProps}
			>
				<DialogTitle handleClose={handleClose}>{label}</DialogTitle>

				<DialogContent sx={{ py: 0, px: 2 }}>{children}</DialogContent>

				<DialogActions sx={{ p: 2 }}>
					<Button
						startIcon={<CloseIcon />}
						onClick={handleClose}
						variant="outlined"
						color="error"
					>
						Cancel
					</Button>

					{handleSpecialSave && (
						<Button
							disabled={canSave === undefined ? false : !canSave}
							startIcon={<ScheduleSendIcon />}
							onClick={handleSpecialSave}
							variant="contained"
							color="error"
						>
							Schedule
						</Button>
					)}

					<Button
						disabled={canSave === undefined ? false : !canSave}
						startIcon={<SaveIcon />}
						onClick={handleSave}
						variant="contained"
					>
						{saveButtonText}
					</Button>
				</DialogActions>
			</Dialog>

			<ResultSnackbar
				open={setSuccess && success}
				message={successMessage}
				handleClose={() => {
					if (!setSuccess) return;
					setSuccess(false);
				}}
			/>

			<Button
				{...buttonProps}
				onClick={() => {
					setIsOpen(true);
					onOpen?.();
				}}
				disabled={disabled}
			>
				{label}
			</Button>
		</>
	);
};
