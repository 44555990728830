import { Create, SimpleForm, TextInput } from 'react-admin';

import { ClientAuthReferenceArrayInput } from '../ClientAuth/components';
import { FeatureFlagReferenceArrayInput } from '../FeatureFlag/FeatureFlagReferenceArrayInput';

export const ClientGroupCreate: React.FC = () => (
	<Create>
		<SimpleForm>
			<TextInput source="name" />
			<TextInput source="description" />
			<ClientAuthReferenceArrayInput source="clients_ids" />
			<FeatureFlagReferenceArrayInput source="features_ids" />
		</SimpleForm>
	</Create>
);
