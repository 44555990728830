import { FieldProps, MerchantOptionFieldPropsMap } from './types';

const apexCardConnectFields: FieldProps[] = [
	{ id: 'user', label: 'Merchant User Name' },
	{ id: 'password', label: 'Merchant Password' },
	{ id: 'mid', label: 'Merchant ID' }
];
const moolahFields: FieldProps[] = [{ id: 'key', label: 'API Key' }];

export const merchantFieldPropsMap: MerchantOptionFieldPropsMap = {
	apex: apexCardConnectFields,
	cardconnect: apexCardConnectFields,
	moolah: moolahFields
};
