import React, { useState } from 'react';
import {
	EditBase,
	TabbedForm,
	Title,
	Toolbar,
	SaveButton,
	useGetRecordId,
	useRecordContext,
	useSaveContext
} from 'react-admin';
import { Card, Grid, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { ClientAuthSummaryTab } from './components/ClientAuthSummaryTab';
import { ClientAuthConfigTab } from './components/ClientAuthConfigTab';
import { ClientAuthLogTab } from './components/ClientAuthLogTab';
import { ClientAuthSideBar } from './components/ClientAuthSideBar';
import { Schema } from '../../types';

interface ClientAuthEditToolbarProps {
	editMode: boolean;
	toggleEditMode: () => void;
	handleSaveClick: () => void;
	handleCancelClick: () => void;
}

export const ClientAuthEditToolbar: React.FC<ClientAuthEditToolbarProps> = ({
	editMode,
	toggleEditMode,
	handleSaveClick,
	handleCancelClick
}) => {
	return (
		<Toolbar
			sx={{
				justifyContent: 'center',
				zIndex: 999,
				background: 'transparent',
				position: 'fixed',
				bottom: 0,
				right: '2rem',
				width: 'fit-content'
			}}
		>
			{editMode ? (
				<>
					<SaveButton
						type="button"
						label="Save"
						size="large"
						onClick={handleSaveClick}
					/>
					<Button
						variant="outlined"
						color="secondary"
						size="large"
						onClick={handleCancelClick}
						sx={{ marginLeft: 1 }}
					>
						Cancel
					</Button>
				</>
			) : (
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={toggleEditMode}
					startIcon={<EditIcon />}
				>
					Edit
				</Button>
			)}
		</Toolbar>
	);
};

const ClientAuthEditTitle = () => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	return <Title title={String(record?.client.practice_name ?? id)} />;
};

export const ClientAuthEdit: React.FC = () => {
	const [editMode, setEditMode] = useState(false);
	const saveContext = useSaveContext();
	const toggleEditMode = () => {
		setEditMode(prev => !prev);
	};

	const handleFormSubmit = async (values: Partial<Schema.ClientAuth>) => {
		if (!saveContext) {
			console.error('Save context not found');
			return;
		}
		saveContext.save?.(values as Schema.ClientAuth);
		setEditMode(false);
	};

	const handleCancelClick = () => {
		toggleEditMode();
	};

	return (
		<EditBase sx={{ '& .RaEdit-main': { mt: 0 } }} redirect={false}>
			<ClientAuthEditTitle />

			<Grid container spacing={2}>
				<Grid item xs={12} md={8} xl={9} order={{ xs: 1, md: 0 }}>
					<Card>
						<TabbedForm
							toolbar={
								<ClientAuthEditToolbar
									editMode={editMode}
									toggleEditMode={toggleEditMode}
									handleSaveClick={() => {
										document
											.querySelector<HTMLFormElement>(
												'form'
											)
											?.dispatchEvent(
												new Event('submit', {
													cancelable: true,
													bubbles: true
												})
											);
									}}
									handleCancelClick={handleCancelClick}
								/>
							}
							onSubmit={handleFormSubmit}
						>
							<TabbedForm.Tab label="Summary" rowGap={1}>
								<ClientAuthSummaryTab editMode={editMode} />
							</TabbedForm.Tab>

							<TabbedForm.Tab label="Config" gap={1}>
								<ClientAuthConfigTab />
							</TabbedForm.Tab>

							<TabbedForm.Tab label="Out Log">
								<ClientAuthLogTab type="out" />
							</TabbedForm.Tab>

							<TabbedForm.Tab label="Error Log">
								<ClientAuthLogTab type="err" />
							</TabbedForm.Tab>
						</TabbedForm>
					</Card>
				</Grid>

				<Grid item xs={12} md={4} xl={3} order={{ xs: 0, md: 1 }}>
					<ClientAuthSideBar />
				</Grid>
			</Grid>
		</EditBase>
	);
};
