import { useCallback, useMemo, useState, useEffect } from 'react';
import { Alert, Box, TextField } from '@mui/material';
import { useGetRecordId, useRecordContext, useRefresh } from 'react-admin';

import { DialogButton, DialogButtonProps } from '../../../../../components';
import { Schema } from '../../../../../types';
import FlexPackageTypeSelect from './InstallCompleteButton/FlexPackageTypeSelect';
import { FlexPackage } from '../../../../../generated/graphql/types/schema';

export type ResendInstallationEmailButtonProps =
	DialogButtonProps['buttonProps'] & {
		disabled?: boolean;
	};

type Validation = {
	error: boolean;
	errors: string[];
};

const validateEmailRegex =
	// eslint-disable-next-line no-control-regex
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const validateEmailAddress = (value?: string): Validation => {
	if (!value) {
		return {
			error: true,
			errors: ['Please enter an email address.']
		};
	}
	const errors: string[] = [];

	if (!value.match(validateEmailRegex)) {
		errors.push('Please enter a valid email address.');
	}

	return {
		error: errors.length > 0,
		errors
	};
};

export const ResendInstallationEmailButton: React.FC<
	ResendInstallationEmailButtonProps
> = ({ disabled = true, ...props }) => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const refresh = useRefresh();
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [touched, setTouched] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [value, setValue] = useState<string>();
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState(false);
	const [installedFlexPackage, setInstalledFlexPackage] =
		useState<FlexPackage>(record?.client.installedFlexPackage ?? 'FLEX');
	const validation = useMemo(() => validateEmailAddress(value), [value]);
	const validationError = useMemo(
		() => touched && dirty && validation.error,
		[touched, dirty, validation.error]
	);

	useEffect(() => {
		if (record?.client.installedFlexPackage) {
			setInstalledFlexPackage(record.client.installedFlexPackage);
		}
	}, [record?.client.installedFlexPackage]);

	const reset = useCallback(() => {
		setLoading(false);
		setValue(undefined);
		setError(undefined);
		setTouched(false);
		setDirty(false);
		setIsOpen(false);
		refresh();
	}, [refresh]);

	const handleSave = useCallback(async () => {
		if (validation.error) return;

		setLoading(true);
		try {
			if (!record?.client.flex_url) {
				throw new Error('Client does not have a flex URL');
			}

			const res = await fetch('/secure/resendInstallEmail', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify({
					clientId: id,
					url: record.client.flex_url,
					email: value,
					installedFlexPackage: installedFlexPackage
				})
			});

			if (!res.ok) {
				throw new Error(`Error: ${res.status} ${res.statusText}`);
			}

			setSuccess(true);
			reset();
		} catch (err) {
			let message = String(err);
			if (err instanceof Error) message = err.message;

			setLoading(false);
			setError(message);
		}
	}, [
		validation.error,
		record?.client.flex_url,
		id,
		value,
		installedFlexPackage,
		reset
	]);

	return (
		<DialogButton
			isOpen={isOpen}
			handleClose={reset}
			handleSave={handleSave}
			setIsOpen={setIsOpen}
			canSave={!validation.error && !loading}
			label="Resend Installation Email"
			buttonProps={props}
			dialogProps={{ maxWidth: 'sm' }}
			success={success}
			setSuccess={setSuccess}
			successMessage="Install complete email sent."
			saveButtonText="Send Email"
			disabled={disabled}
		>
			{!record?.client.flex_url && (
				<Alert severity="error">
					Client must have a flex URL before installation email can be
					sent.
				</Alert>
			)}
			<Box sx={{ my: 1 }}>
				<FlexPackageTypeSelect
					onBlur={() => {
						if (!touched && dirty) setTouched(true);
					}}
					defaultValue={record?.client.installedFlexPackage}
					onChange={selectedPackage => {
						if (!dirty) setDirty(true);
						setInstalledFlexPackage(selectedPackage);
					}}
				/>
			</Box>
			<TextField
				disabled={!record?.client.flex_url}
				fullWidth
				variant="outlined"
				label="Email"
				type="email"
				error={validationError}
				value={value}
				onBlur={() => {
					if (!touched && dirty) setTouched(true);
				}}
				onChange={e => {
					if (!dirty) setDirty(true);
					setValue(e.target.value);
				}}
				helperText={
					validationError ? (
						<>
							{validation.errors.map(error => (
								<div>{error}</div>
							))}
						</>
					) : (
						''
					)
				}
			/>
			{error && <Alert severity="error">{error}</Alert>}
		</DialogButton>
	);
};
