import { Schema } from '../../../types';

export const clientAuthSearch = (
	value?: string
): Schema.ClientAuthWhereInput => {
	if (!value) return {};
	value = value.trim();

	if (value === '') return {};

	const valueAsNumber = Number(value);
	const isValueNaN = isNaN(valueAsNumber);

	const filters: Schema.ClientAuthWhereInput['OR'] = [
		{
			client: {
				is: {
					OR: [
						{ practice_name: { contains: value } },
						{ main_contact: { contains: value } }
					]
				}
			}
		}
	];

	if (!isValueNaN) {
		filters.push({ id: { equals: valueAsNumber } });
	}

	if (isValueNaN && !value.match(/[^a-f0-9]/g)) {
		filters.push({ client_identifier: { contains: value } });
	}

	return { OR: filters };
};
