import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export type FeatureFlagReferenceArrayInputProps = {
	source: string;
	disabled?: boolean;
};

export const FeatureFlagReferenceArrayInput: React.FC<
	FeatureFlagReferenceArrayInputProps
> = ({ source, disabled = false }) => (
	<ReferenceArrayInput reference="FeatureFlag" source={source}>
		<AutocompleteArrayInput
			fullWidth
			disabled={disabled}
			optionText={record => record.id}
			filterToQuery={val => ({
				OR: [
					{ id: { contains: val } },
					{ description: { contains: val } }
				]
			})}
		/>
	</ReferenceArrayInput>
);
