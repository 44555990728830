import ReactDOM from 'react-dom/client';

import { App } from './App';
import { SnackbarProvider } from './components/SnackbarProvider'; // Import the SnackbarProvider

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<SnackbarProvider>
		<App />
	</SnackbarProvider>
);
