import { Create, SimpleForm, TextInput } from 'react-admin';

import { ClientAuthReferenceArrayInput } from '../ClientAuth/components';
import { ClientGroupReferenceArrayInput } from '../ClientGroup/ClientGroupReferenceArrayInput';

export const FeatureFlagCreate: React.FC = () => (
	<Create>
		<SimpleForm>
			<TextInput source="id" />
			<TextInput source="description" />
			<ClientAuthReferenceArrayInput source="clients_ids" />
			<ClientGroupReferenceArrayInput source="clientGroups_ids" />
		</SimpleForm>
	</Create>
);
