import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

import { clientAuthSearch } from '../lib/clientAuthSearch';

type ClientAuthReferenceInputProps = {
	source: string;
	disabled?: boolean;
};

export const ClientAuthReferenceArrayInput: React.FC<
	ClientAuthReferenceInputProps
> = ({ source, disabled = false }) => (
	<ReferenceArrayInput reference="ClientAuth" source={source}>
		<AutocompleteArrayInput
			fullWidth
			filterToQuery={clientAuthSearch}
			disabled={disabled}
		/>
	</ReferenceArrayInput>
);
