import { useCallback } from 'react';
import { useGetRecordId, useRecordContext } from 'react-admin';

import { ConfirmationDialogButton } from '../../../../../components';
import { ButtonPropsNoClick, Schema } from '../../../../../types';

export const UpdateButton: React.FC<ButtonPropsNoClick> = props => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	const onConfirm = useCallback(async () => {
		if (!record?.client_identifier) return;

		try {
			const res = await fetch(
				`/secure/forceUpdate?client_identifier=${record.client_identifier}`
			);

			if (!res.ok)
				throw new Error(`Error: ${res.status}: ${res.statusText}`);

			return { success: true };
		} catch (err) {
			let message = String(err);

			if (err instanceof Error) message = err.message;

			return { success: false, error: message };
		}
	}, [record?.client_identifier]);

	return (
		<ConfirmationDialogButton
			onConfirm={onConfirm}
			label="Update"
			message="Force updating may cause flex to stop for an unknown period of time."
			title={`Force update client ${id}?`}
			successMessage={`Force updated client ${id}.`}
			buttonProps={props}
		/>
	);
};
