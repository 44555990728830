import { EditBase, useRecordContext } from 'react-admin';
import './a2p/index.scss';

import A2pDetails from './a2p/RecordDetails';
const DetailInstance = () => {
	const record = useRecordContext();
	if (!record) return <h2>Loading</h2>;
	return <A2pDetails record_id={record.client_id} />;
};

export const ClientRegistrationEdit: React.FC = () => (
	<EditBase>
		<DetailInstance />
	</EditBase>
);

// <Edit>
// 	<SimpleForm>
// 		<ReferenceInput source="client_id" reference="clients" />
// 		<TextInput source="dev_status" />
// 		<TextInput source="error_email_sent" />
// 		<NumberInput source="has_error" />
// 		<TextInput source="id" />
// 		<DateInput source="locked_for_processing_at" />
// 		<NumberInput source="next" />
// 		<TextInput source="note" />
// 		<TextInput source="objects.flex_client_attrs.fee" />
// 		<TextInput source="overrides" />
// 		<TextInput source="status" />
// 		<TextInput source="status_context" />
// 	</SimpleForm>
// </Edit>
