import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Snackbar, Alert, AlertProps } from '@mui/material';

interface SnackbarContextType {
	showSnackbar: (message: string, severity?: AlertProps['severity']) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
	undefined
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
	children
}) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] =
		useState<AlertProps['severity']>('success');
	const [snackbarDuration, setSnackbarDuration] = useState(6000);

	const showSnackbar = (
		message: string,
		severity: AlertProps['severity'] = 'success'
	) => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);

		// Adjust duration based on severity
		if (severity === 'error') {
			setSnackbarDuration(10000);
		} else {
			setSnackbarDuration(6000);
		}

		setSnackbarOpen(true);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const getStyles = (severity: AlertProps['severity']) => {
		switch (severity) {
			case 'success':
				return {
					backgroundColor: 'green',
					color: 'white',
					'& .MuiAlert-icon': { color: 'white' }
				};
			case 'info':
				return {
					backgroundColor: 'blue',
					color: 'white',
					'& .MuiAlert-icon': { color: 'white' }
				};
			case 'warning':
				return {
					backgroundColor: 'orange',
					color: 'white',
					'& .MuiAlert-icon': { color: 'white' }
				};
			case 'error':
				return {
					backgroundColor: 'red',
					color: 'white',
					'& .MuiAlert-icon': { color: 'white' }
				};
			default:
				return {
					backgroundColor: 'green',
					color: 'white',
					'& .MuiAlert-icon': { color: 'white' }
				};
		}
	};

	return (
		<SnackbarContext.Provider value={{ showSnackbar }}>
			{children}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={snackbarDuration}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					sx={{
						width: '100%',
						...getStyles(snackbarSeverity)
					}}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};

export const useSnackbar = (): SnackbarContextType => {
	const context = useContext(SnackbarContext);
	if (!context) {
		throw new Error('useSnackbar must be used within a SnackbarProvider');
	}
	return context;
};
