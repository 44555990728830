import {
	Edit,
	Labeled,
	SimpleForm,
	TextField,
	TextInput,
	usePermissions
} from 'react-admin';

import { ClientAuthReferenceArrayInput } from '../ClientAuth/components';
import { ClientGroupReferenceArrayInput } from '../ClientGroup/ClientGroupReferenceArrayInput';

export const FeatureFlagEdit: React.FC = () => {
	const { permissions } = usePermissions<string[]>();
	const hasPermission = (permission: string) =>
		permissions?.includes(permission);
	return (
		<Edit>
			<SimpleForm>
				<Labeled>
					<TextField source="id" />
				</Labeled>

				<TextInput
					source="description"
					disabled={
						!hasPermission('Modify Client Group or Feature Flag')
					}
				/>
				<TextInput
					source="gaVersion"
					disabled={!hasPermission('Set GA Version on Feature Flag')}
				/>
				<ClientAuthReferenceArrayInput source="clients_ids" />
				<ClientGroupReferenceArrayInput source="clientGroups_ids" />
			</SimpleForm>
		</Edit>
	);
};
