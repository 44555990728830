import { useState, useCallback } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	Switch,
	FormControlLabel
} from '@mui/material';
import { SubxCancelModal } from './SubxCancelModal';
import { useSnackbar } from '../../../../../components';

interface SubxMgmtModalProps {
	clientId: number;
	baseActive: boolean | null | undefined;
	osActive: boolean | null | undefined;
}

export const SubxMgmtModal: React.FC<SubxMgmtModalProps> = ({
	clientId,
	baseActive: initialBaseActive,
	osActive: initialOsActive
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [baseActive, setBaseActive] = useState(initialBaseActive);
	const [osActive, setOsActive] = useState(initialOsActive);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const { showSnackbar } = useSnackbar();

	const handleSave = useCallback(async () => {
		try {
			const response = await fetch('/secure/admin/editSubscription', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					clientId,
					base_active: baseActive,
					os_active: osActive
				})
			});
			if (!response.ok) {
				showSnackbar('Failed to modify subscription 😱', 'error');
				throw new Error('Failed to update subscription');
			}
			showSnackbar('Subscription modified successfully!', 'success');
		} catch (error) {
			showSnackbar('Failed to modify subscription 😱', 'error');
		} finally {
			setIsOpen(false);
		}
	}, [baseActive, osActive, clientId, showSnackbar]);

	return (
		<>
			<Button onClick={() => setIsOpen(true)}>Manage Subscription</Button>
			<Dialog open={isOpen} onClose={() => setIsOpen(false)}>
				<DialogTitle>Subscription Management</DialogTitle>
				<DialogContent>
					<FormControlLabel
						control={
							<Switch
								checked={baseActive === true}
								onChange={() => setBaseActive(prev => !prev)}
								name="Base Flex"
							/>
						}
						label="Base Flex"
					/>
					<FormControlLabel
						control={
							<Switch
								checked={osActive === true}
								onChange={() => setOsActive(prev => !prev)}
								name="FlexSchedule"
							/>
						}
						label="FlexSchedule"
					/>
					<Button onClick={() => setIsCancelModalOpen(true)}>
						Cancel Subscription
					</Button>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setIsOpen(false)}>Close</Button>
					<Button onClick={handleSave}>Save</Button>
				</DialogActions>
			</Dialog>
			{isCancelModalOpen && (
				<SubxCancelModal
					clientId={clientId}
					onClose={() => setIsCancelModalOpen(false)}
				/>
			)}
		</>
	);
};
