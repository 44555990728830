import { usePermissions } from 'react-admin';
import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';

export type RequiresPermissionsProps = React.PropsWithChildren & {
	requiredPermissions: string[];
	fallback?: JSX.Element;
};

export const RequiresPermissions: React.FC<RequiresPermissionsProps> = ({
	requiredPermissions,
	fallback,
	children
}) => {
	const { permissions, isLoading } = usePermissions<string[]>();
	const hasPermissions = useMemo(
		() =>
			requiredPermissions.every(permission =>
				permissions?.includes(permission)
			),
		[permissions, requiredPermissions]
	);

	if (isLoading) return <CircularProgress />;

	return <>{hasPermissions ? children : fallback}</>;
};
