import { useState, useCallback } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	TextField
} from '@mui/material';
import { useSnackbar } from '../../../../../components';

interface SubxCancelModalProps {
	clientId: number;
	onClose: () => void;
}

export const SubxCancelModal: React.FC<SubxCancelModalProps> = ({
	clientId,
	onClose
}) => {
	const { showSnackbar } = useSnackbar();
	const [cancelledDate, setCancelledDate] = useState(
		new Date().toISOString().split('T')[0]
	);
	const [cancelledReason, setCancelledReason] = useState('');

	const handleConfirm = useCallback(async () => {
		if (!cancelledReason.trim()) {
			window.alert('Cancellation reason is required.');
			return;
		}

		// Confirm the cancellation action with the user
		const isConfirmed = window.confirm(
			'Are you sure you want to cancel this subscription?'
		);
		if (!isConfirmed) {
			return;
		}
		try {
			const response = await fetch('/secure/admin/cancelSubscription', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					clientId,
					cancelled_date: cancelledDate,
					cancelled_reason: cancelledReason
				})
			});
			if (!response.ok) {
				showSnackbar('Failed to cancel subscription 😱', 'error');
				throw new Error('Failed to cancel subscription');
			}
			showSnackbar('Subscription cancelled 😭', 'success');
			onClose();
		} catch (error) {
			showSnackbar('Failed to cancel subscription 😱', 'error');
		}
	}, [cancelledDate, cancelledReason, clientId, onClose, showSnackbar]);

	return (
		<Dialog open onClose={onClose}>
			<DialogTitle>Cancel Subscription</DialogTitle>
			<DialogContent>
				<TextField
					label="Cancellation Date"
					type="date"
					value={cancelledDate}
					onChange={e => setCancelledDate(e.target.value)}
					fullWidth
				/>
				<TextField
					label="Cancellation Reason"
					value={cancelledReason}
					onChange={e => setCancelledReason(e.target.value)}
					fullWidth
					multiline
					rows={3}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
				<Button onClick={handleConfirm}>Confirm Cancellation</Button>
			</DialogActions>
		</Dialog>
	);
};
