import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export type ClientGroupReferenceArrayInputProps = {
	source: string;
	disabled?: boolean;
};

const filter = (value: string) => {
	if (!value) return {};
	value = value.trim();

	if (value === '') return {};

	const valueAsNumber = Number(value);
	const isValueNaN = isNaN(valueAsNumber);

	const filters: Record<string, unknown>[] = [
		{ name: { contains: value } },
		{ description: { contains: value } }
	];

	if (!isValueNaN) {
		filters.push({ id: { equals: valueAsNumber } });
	}

	return { OR: filters };
};

export const ClientGroupReferenceArrayInput: React.FC<
	ClientGroupReferenceArrayInputProps
> = ({ source, disabled = false }) => (
	<ReferenceArrayInput reference="ClientGroup" source={source}>
		<AutocompleteArrayInput
			fullWidth
			optionText={record =>
				`${record.id}: ${record.description} (${record.clients.length})`
			}
			filterToQuery={filter}
			disabled={disabled}
		/>
	</ReferenceArrayInput>
);
