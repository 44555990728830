import { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { ButtonPropsNoClick } from '../types';
import { ResultSnackbar } from '.';

type ResultDetails = {
	success: boolean;
	error?: string;
	successMessage?: string;
};

type ConfirmationResult = ResultDetails | void | undefined;

export type ConfirmationDialogButtonProps = {
	label: string;
	title?: string;
	message?: string;
	cancelLabel?: string;
	confirmLabel?: string;
	successMessage?: string;
	buttonProps?: ButtonPropsNoClick;
	dialogProps?: Omit<DialogProps, 'open'>;
	onConfirm: () => Promise<ConfirmationResult> | ConfirmationResult;
};

export const ConfirmationDialogButton: React.FC<
	ConfirmationDialogButtonProps
> = ({
	label,
	title = label,
	message = 'Are you sure?',
	cancelLabel = 'Cancel',
	confirmLabel = label,
	successMessage = 'Success!',
	dialogProps,
	buttonProps,
	onConfirm
}) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [successMessageDynamic, setSuccessMessageDynamic] =
		useState(successMessage);
	const [errorMessage, setErrorMessage] = useState('Something went wrong.');

	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={open} {...dialogProps}>
				<DialogTitle>{title}</DialogTitle>

				<DialogContent dividers>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button
						disabled={loading}
						startIcon={<CloseIcon />}
						onClick={() => setOpen(false)}
						variant="outlined"
						color="error"
					>
						{cancelLabel}
					</Button>

					<LoadingButton
						loading={loading}
						startIcon={<CheckIcon />}
						onClick={async () => {
							setLoading(true);
							const result = await onConfirm();

							setOpen(false);
							setLoading(false);

							if (!result) {
								setSuccess(true);
								return;
							}

							if (result.error) {
								setErrorMessage(result.error);
							}

							if (result.successMessage) {
								setSuccessMessageDynamic(result.successMessage);
							}

							setSuccess(result.success);
							setError(!result.success);
						}}
						variant="contained"
					>
						{confirmLabel}
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<ResultSnackbar
				open={success}
				handleClose={() => setSuccess(false)}
				message={successMessageDynamic}
			/>
			<ResultSnackbar
				open={error}
				handleClose={() => setError(false)}
				message={errorMessage}
				severity="error"
			/>

			<Button {...buttonProps} onClick={() => setOpen(true)}>
				{label}
			</Button>
		</>
	);
};
