import {
	DateField,
	TextField,
	TextInput,
	PasswordInput,
	FunctionField,
	Labeled,
	ReferenceInput,
	AutocompleteInput,
	useGetRecordId,
	BooleanInput,
	useRecordContext,
	BooleanField,
	TextInputProps,
	TextFieldProps,
	usePermissions
} from 'react-admin';
import React, { createContext, useContext } from 'react';
import { Grid, GridProps } from '@mui/material';

import { ClientGroupReferenceArrayInput } from '../../ClientGroup/ClientGroupReferenceArrayInput';
import { FeatureFlagReferenceArrayInput } from '../../FeatureFlag/FeatureFlagReferenceArrayInput';
import { clientAuthSearch } from '../lib';
import { Schema } from '../../../types';
import { flexPackages } from './ClientAuthSideBar/components/InstallCompleteButton/FlexPackageTypeSelect';
import { RequiresPermissions } from '../../../components/PermissionGuard';

type SummaryGridItemProps = Pick<
	GridProps,
	'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'alignSelf' | 'maxWidth'
> & {
	children: React.ReactElement;
	noLabel?: boolean;
	fullWidth?: boolean;
	halfWidth?: boolean;
};

const SummaryGridItem: React.FC<SummaryGridItemProps> = ({
	children,
	noLabel,
	fullWidth,
	halfWidth,
	...props
}) => {
	const childrenOutput = noLabel ? children : <Labeled>{children}</Labeled>;

	if (halfWidth) {
		return (
			<Grid item xs={12} sm={6} {...props}>
				{childrenOutput}
			</Grid>
		);
	}

	if (fullWidth) {
		return (
			<Grid item xs={12} {...props}>
				{childrenOutput}
			</Grid>
		);
	}

	return (
		<Grid item xs={12} sm={6} md={4} lg={3} xl={2} {...props}>
			{childrenOutput}
		</Grid>
	);
};

const AgentOperatingSystemField: React.FC = () => {
	const record = useRecordContext();

	const agentOperatingSystem = record?.agentOperatingSystem;

	return (
		<SummaryGridItem>
			<TextField
				source="agentOperatingSystem"
				color={
					agentOperatingSystem === 'Server 2012' ? 'error' : 'initial'
				}
			/>
		</SummaryGridItem>
	);
};

const WisetackIntegrationDetails: React.FC = () => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const wisetackIntegration = record?.wisetackIntegration[0];

	if (!wisetackIntegration) return <></>;

	return (
		<>
			<SummaryGridItem lg={6}>
				<FunctionField
					label="Wisetack Merchant ID"
					render={() => wisetackIntegration?.merchantId}
				/>
			</SummaryGridItem>

			<SummaryGridItem lg={6}>
				<FunctionField
					label="Wisetack Application Status"
					render={() => wisetackIntegration?.status}
				/>
			</SummaryGridItem>
		</>
	);
};

const EditModeContext = createContext<boolean>(false);

export const useEditMode = (): boolean => {
	return useContext(EditModeContext);
};

type ConditionalInputProps = {
	source: string;
	label?: string;
	summaryGridItemProps?: SummaryGridItemProps;
	inputProps?: Omit<TextInputProps, 'label' | 'source'>;
	fieldProps?: Omit<TextFieldProps, 'label' | 'source'>;
};
const ConditionalInput: React.FC<ConditionalInputProps> = ({
	inputProps,
	fieldProps,
	summaryGridItemProps,
	...props // source and label
}) => {
	const editMode = useEditMode();
	const field = (
		<SummaryGridItem {...summaryGridItemProps}>
			<TextField {...props} {...fieldProps} />
		</SummaryGridItem>
	);
	if (!editMode) return field;
	return (
		<RequiresPermissions
			requiredPermissions={['edit-client-details']}
			fallback={field}
		>
			<SummaryGridItem noLabel {...summaryGridItemProps}>
				<TextInput {...props} {...inputProps} />
			</SummaryGridItem>
		</RequiresPermissions>
	);
};

export const ClientAuthSummaryTab: React.FC<{ editMode: boolean }> = ({
	editMode
}) => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const shouldAutoUpdate = record?.should_auto_update;

	const { permissions } = usePermissions<string[]>();
	const hasPermission = (permission: string) =>
		permissions?.includes(permission);

	const isEditingDisabled = (permission: string) => {
		return !editMode || !hasPermission(permission);
	};

	return (
		<EditModeContext.Provider value={editMode}>
			<Grid container spacing={3} rowSpacing={1}>
				<SummaryGridItem noLabel fullWidth>
					<h3>Identity</h3>
				</SummaryGridItem>
				<SummaryGridItem>
					<TextField source="id" />
				</SummaryGridItem>

				<ConditionalInput
					label="Practice"
					source="client.practice_name"
				/>

				<SummaryGridItem fullWidth>
					<div></div>
				</SummaryGridItem>

				<SummaryGridItem halfWidth xl={6}>
					<TextField source="client_identifier" label="Client ID" />
				</SummaryGridItem>

				<SummaryGridItem noLabel halfWidth xl={6}>
					<PasswordInput
						fullWidth
						disabled
						helperText=""
						source="client_key"
					/>
				</SummaryGridItem>

				<SummaryGridItem noLabel fullWidth>
					<div>
						<hr></hr>
						<h3>Contact</h3>
					</div>
				</SummaryGridItem>

				<ConditionalInput
					label="Main Contact"
					source="client.main_contact"
				/>

				<ConditionalInput label="Phone" source="client.phone" />

				<ConditionalInput label="Email" source="client.email" />

				<SummaryGridItem>
					<TextField source="sms.smsnumber" label="SMS Number" />
				</SummaryGridItem>

				<SummaryGridItem fullWidth>
					<div></div>
				</SummaryGridItem>

				<ConditionalInput label="Website" source="client.website" />

				<ConditionalInput
					label="Address"
					source="client.practice_address"
				/>

				<SummaryGridItem>
					<FunctionField
						label="Billing Address"
						render={(record: Schema.ClientAuth) => (
							<span>
								{record.client.address}
								<br />
								{record.client.city}, {record.client.state}{' '}
								{record.client.zip}
							</span>
						)}
					/>
				</SummaryGridItem>
			</Grid>

			<Grid container spacing={3} rowSpacing={1}>
				<SummaryGridItem noLabel fullWidth>
					<div>
						<hr></hr>
						<h3>Agent Demographics</h3>
					</div>
				</SummaryGridItem>

				<SummaryGridItem>
					<DateField showTime source="last_auth" label="Last Auth" />
				</SummaryGridItem>

				<SummaryGridItem>
					<TextField source="version" label="Installed Version" />
				</SummaryGridItem>

				<SummaryGridItem noLabel>
					<TextInput
						source="target_version"
						label="Target Version"
						helperText=""
						disabled={!editMode}
					/>
				</SummaryGridItem>

				<SummaryGridItem noLabel>
					<BooleanInput
						source="should_auto_update"
						label="Auto Update"
						disabled={
							!editMode ||
							/* disable the ability to enable IF its already enabled AND the user lacks the Update permissions */
							(shouldAutoUpdate &&
								!hasPermission('Enable Auto Update')) ||
							/* disable the ability to disable IF its already disabled AND the user lacks the Disable permissions */
							(!shouldAutoUpdate &&
								!hasPermission('Disable Auto Update'))
						}
					/>
				</SummaryGridItem>

				<SummaryGridItem fullWidth>
					<div></div>
				</SummaryGridItem>

				<ConditionalInput label="Flex URL" source="client.flex_url" />

				<AgentOperatingSystemField />

				<SummaryGridItem>
					<TextField source="pmsDBEngines" label="PMS DB Engine(s)" />
				</SummaryGridItem>
				<SummaryGridItem>
					<BooleanField
						source="pmsDBIsLocal"
						label="PMS DB Local to Agent"
					/>
				</SummaryGridItem>
				<SummaryGridItem>
					<TextField
						source="pmsDBSchemaVersion"
						label="PMS DB Schema Version"
					/>
				</SummaryGridItem>
				<SummaryGridItem>
					<TextField source="pmsDBVersion" label="PMS DB Version" />
				</SummaryGridItem>
				<SummaryGridItem>
					<TextField source="chromeVersion" label="Chrome Version" />
				</SummaryGridItem>

				<SummaryGridItem noLabel fullWidth>
					<div>
						<hr></hr>
						<h3>Misc</h3>
					</div>
				</SummaryGridItem>

				<SummaryGridItem>
					<DateField label="Start" source="client.create_date" />
				</SummaryGridItem>

				<SummaryGridItem>
					<DateField
						label="Cancelled"
						source="client.cancelled_date"
					/>
				</SummaryGridItem>

				<SummaryGridItem>
					<FunctionField
						label="Sent Install Email"
						render={(record: Schema.ClientAuth) =>
							record.client.install_complete ? (
								`${
									flexPackages.find(
										flexPackageItem =>
											flexPackageItem.value ===
											record.client.installedFlexPackage
									)?.name
								}`
							) : (
								<>-</>
							)
						}
					/>
				</SummaryGridItem>

				<SummaryGridItem>
					<TextField
						label="Subscription"
						source="client.subscription_id"
					/>
				</SummaryGridItem>

				<SummaryGridItem>
					<TextField source="merchant" />
				</SummaryGridItem>

				<SummaryGridItem>
					<TextField source="MID" label="MID" />
				</SummaryGridItem>

				<SummaryGridItem>
					<TextField source="os.os_id" label="OS URL" />
				</SummaryGridItem>

				<SummaryGridItem>
					<FunctionField
						label="Financing"
						render={(record: Schema.ClientAuth) => {
							const [wisetackIntegration] =
								record.wisetackIntegration;

							if (!wisetackIntegration) return '-';

							return 'Wisetack';
						}}
					/>
				</SummaryGridItem>

				<WisetackIntegrationDetails />

				<SummaryGridItem noLabel fullWidth>
					<ReferenceInput
						reference="ClientAuth"
						source="os.parent_id"
					>
						<AutocompleteInput
							label="OS Parent"
							filterToQuery={clientAuthSearch}
							emptyValue={id}
							emptyText={String(id)}
							disabled={isEditingDisabled('Set OS Parent')}
						/>
					</ReferenceInput>
				</SummaryGridItem>

				<SummaryGridItem noLabel fullWidth>
					<ClientGroupReferenceArrayInput
						source="clientGroups_ids"
						disabled={isEditingDisabled(
							'Add Client to Client Group'
						)}
					/>
				</SummaryGridItem>

				<SummaryGridItem noLabel fullWidth>
					<FeatureFlagReferenceArrayInput
						source="featureFlags_ids"
						disabled={!editMode}
					/>
				</SummaryGridItem>
			</Grid>
		</EditModeContext.Provider>
	);
};
