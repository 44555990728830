import { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import { ConfirmationDialogButton } from '../../../../../components';
import { ButtonPropsNoClick, Schema } from '../../../../../types';

export const GetClientDirectoryButton: React.FC<ButtonPropsNoClick> = props => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	const onConfirm = useCallback(() => {
		if (!record?.client_identifier) return;

		try {
			window.open(
				`/secure/getFlexDirectoryTree?client_identifier=${record.client_identifier}`
			);

			return { success: true };
		} catch (err) {
			let message = String(err);

			if (err instanceof Error) message = err.message;

			return { success: false, error: message };
		}
	}, [record?.client_identifier]);

	return (
		<ConfirmationDialogButton
			onConfirm={onConfirm}
			label="Get Client Directory"
			message="Fetch the client's directory? This will open in a new tab."
			buttonProps={props}
		/>
	);
};
