import {
	BooleanField,
	Labeled,
	useRecordContext,
	usePermissions
} from 'react-admin';
import { useEffect, useState } from 'react';
import { Box, ButtonProps, Stack, Card, CardContent } from '@mui/material';

import {
	ChangeSMSNumberButton,
	GetSMSNumberButton,
	ResendInstallationEmailButton,
	UpdateButton,
	FetchDatabaseButton,
	DownloadDatabaseButton,
	ResetButton,
	InstallCompleteButton,
	GetClientDirectoryButton,
	DownloadLogArchiveButton,
	FlexPayButton,
	DownloadAssetFromS3Button
} from './components';
import { SubxMgmtModal } from './components/SubxMgmtModal';
import { Schema } from '../../../../types';
import { defined } from '../../../../lib';

const SideBarButtons: React.FC<ButtonProps> = ({ ...props }) => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const { permissions, isLoading } = usePermissions();
	const [hasSubxMgmtPermission, setHasSubxMgmtPermission] = useState(false);

	useEffect(() => {
		if (!isLoading && permissions) {
			setHasSubxMgmtPermission(permissions.includes('subx-mgmt'));
		}
	}, [permissions, isLoading]);

	const hasPermission = (permission: string) => {
		return permissions?.includes(permission) ?? false;
	};

	const isButtonDisabled = (permission: string) => {
		return !hasPermission(permission);
	};

	return (
		<Stack
			gap={1}
			direction={{ xs: 'row', md: 'column' }}
			flexWrap={{ xs: 'wrap', md: 'nowrap' }}
		>
			<InstallCompleteButton
				{...props}
				disabled={false}
				sx={{
					...props.sx,
					// need to hide this with css instead of conditionally rendering
					// b/c the success snackbar is a child of the button
					...(record?.client.install_complete
						? { display: 'none' }
						: {})
				}}
			/>

			<ResendInstallationEmailButton
				{...props}
				disabled={isButtonDisabled('Resend Installation Email')}
				sx={{
					...props.sx,
					// same as above: must be hidden with css
					...(record?.client.install_complete
						? {}
						: { display: 'none' })
				}}
			/>

			<GetSMSNumberButton
				{...props}
				disabled={isButtonDisabled('Change SMS Number')}
				sx={{
					...props.sx,
					// same as above: must be hidden with css
					...(record?.sms?.smsnumber ? { display: 'none' } : {})
				}}
			/>

			<ChangeSMSNumberButton
				{...props}
				disabled={isButtonDisabled('Change SMS Number')}
			/>

			<FlexPayButton
				isChange={defined(record?.merchant)}
				{...props}
				disabled={isButtonDisabled('Change FlexPay')}
			/>

			<UpdateButton
				disabled={isButtonDisabled('Update Agent')}
				{...props}
			/>

			{record?.showDownloadLogArchive && (
				<DownloadLogArchiveButton {...props} />
			)}

			{record?.showDownloadDBViaS3 ? (
				<DownloadAssetFromS3Button {...props} assetType="DB">
					Download Database
				</DownloadAssetFromS3Button>
			) : (
				<DownloadDatabaseButton
					disabled={isButtonDisabled('Download Database')}
					{...props}
				/>
			)}

			{record?.showDownloadLogArchiveViaS3 && (
				<DownloadAssetFromS3Button
					disabled={isButtonDisabled('Download Log Archive')}
					{...props}
					assetType="LOG_ARCHIVE"
				>
					Download Log Archive
				</DownloadAssetFromS3Button>
			)}

			{record?.showGetFlexDirectory && (
				<GetClientDirectoryButton
					disabled={isButtonDisabled('Get Client Directory')}
					{...props}
				/>
			)}

			{!record?.hideDatabaseFetch && (
				<FetchDatabaseButton
					disabled={isButtonDisabled('Download Database')}
					{...props}
				/>
			)}

			{record && hasSubxMgmtPermission && (
				<SubxMgmtModal
					clientId={Number(record.id)}
					baseActive={record.base_active}
					osActive={record.os_active}
				/>
			)}

			<ResetButton
				disabled={isButtonDisabled('Download Database')}
				{...props}
			/>
		</Stack>
	);
};

export const ClientAuthSideBar: React.FC = () => (
	<Card>
		<CardContent>
			<Stack direction="row" flexWrap="wrap" spacing={1} mb={2}>
				<Labeled>
					<BooleanField source="isConnected" label="Connected" />
				</Labeled>

				<Labeled>
					<BooleanField source="status" />
				</Labeled>

				<Labeled>
					<BooleanField source="base_active" label="Base" />
				</Labeled>

				<Labeled>
					<BooleanField source="os_active" label="OS" />
				</Labeled>

				<Labeled>
					<BooleanField
						source="isFlexVerifyEnabled"
						label="FlexVerify"
					/>
				</Labeled>
			</Stack>

			<Box display={{ xs: 'none', md: 'block' }}>
				<SideBarButtons size="small" />
			</Box>

			<Box display={{ xs: 'block', md: 'none' }}>
				<SideBarButtons size="small" variant="outlined" />
			</Box>
		</CardContent>
	</Card>
);
