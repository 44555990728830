import {
	BooleanField,
	DatagridConfigurable,
	DatagridConfigurableProps,
	DateField,
	TextField
} from 'react-admin';

export const clientAuthDatagridDefaultProps = {
	rowClick: 'edit',
	bulkActionButtons: false,
	omit: [
		'parent_identifier',
		'pms',
		'base_active',
		'os_beta',
		'cloud_active',
		'used',
		'ip_address',
		'clientGroups',
		'featureFlags',
		'create_date',
		'update_date',
		'client.subscription_id'
	]
} as const;

export const ClientAuthDatagrid: React.FC<DatagridConfigurableProps> = ({
	...props
}) => (
	<DatagridConfigurable
		{...(clientAuthDatagridDefaultProps as unknown as DatagridConfigurableProps)}
		{...props}
	>
		<TextField
			source="id"
			sx={{
				overflowWrap: 'anywhere',
				minWidth: 40,
				display: 'block'
			}}
		/>
		<BooleanField source="isConnected" label="Connected" />
		<TextField label="Practice" source="client.practice_name" />

		<TextField label="Main Contact" source="client.main_contact" />
		<DateField showTime source="last_auth" />
		<TextField source="pms" />
		<BooleanField looseValue source="status" />
		<BooleanField looseValue source="base_active" />
		<BooleanField looseValue source="os_active" label="OS" />
		<BooleanField
			looseValue
			source="isFlexVerifyEnabled"
			label="FlexVerify"
		/>
		<BooleanField looseValue source="os_beta" />
		<BooleanField looseValue source="cloud_active" />
		<BooleanField source="used" />
		<TextField source="version" />
		<TextField source="target_version" label="Target" />
		<BooleanField
			looseValue
			source="should_auto_update"
			label="Auto update"
		/>
		<BooleanField
			label="Install Complete"
			source="client.install_complete"
		/>
		<TextField label="Subscription" source="client.subscription_id" />
		<TextField source="ip_address" />
		<TextField source="clientGroups" />
		<TextField source="featureFlags" />
		<DateField source="create_date" />
		<DateField source="update_date" />
		<TextField source="parent_identifier" />
		<TextField
			source="client_identifier"
			sx={{
				overflowWrap: 'anywhere',
				minWidth: 150,
				display: 'block'
			}}
		/>
	</DatagridConfigurable>
);
