import { useCallback } from 'react';
import { useGetRecordId, useRecordContext } from 'react-admin';

import { ConfirmationDialogButton } from '../../../../../components';
import { ButtonPropsNoClick, Schema } from '../../../../../types';

export const FetchDatabaseButton: React.FC<ButtonPropsNoClick> = props => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	const onConfirm = useCallback(async () => {
		if (!record?.client_identifier) return;

		try {
			const res = await fetch('/secure/fetchClientDB', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify({
					client_identifier: record.client_identifier
				})
			});

			if (res.status === 409)
				throw new Error('Database is currently downloading');

			if (!res.ok)
				throw new Error(
					`client did not respond to request to upload database. Error: ${res.status}: ${res.statusText}`
				);

			return { success: true };
		} catch (err) {
			let message = String(err);

			if (err instanceof Error) message = err.message;

			return { success: false, error: message };
		}
	}, [record?.client_identifier]);

	return (
		<ConfirmationDialogButton
			onConfirm={onConfirm}
			label="Fetch Database"
			message="Please do not perform this action many times in a short period."
			successMessage={`Fetching database for client ${id}.`}
			buttonProps={props}
		/>
	);
};
