import React from 'react';
import {
	Toolbar,
	SaveButton,
	DeleteButton,
	ToolbarProps,
	useRecordContext
} from 'react-admin';

interface PrivilegedToolbarProps extends ToolbarProps {
	disableSave?: boolean;
	disableDelete?: boolean;
}

export const PrivilegedToolbar: React.FC<PrivilegedToolbarProps> = ({
	disableSave,
	disableDelete,
	...props
}) => {
	const record = useRecordContext();

	return (
		<Toolbar {...props}>
			{!disableSave && <SaveButton disabled={disableSave} />}
			{!disableDelete && record && (
				<DeleteButton disabled={disableDelete} />
			)}
		</Toolbar>
	);
};
