import {
	DatagridConfigurable,
	List,
	NumberField,
	TextField,
	FunctionField,
	FilterButton,
	SelectColumnsButton,
	TextInput,
	TopToolbar
} from 'react-admin';
import { Schema } from '../../types';
import { QuickFilter } from '../../components';

const clientRegistrationFilters = [
	<TextInput label="Search" source="clientRegistration_search" alwaysOn />,
	<QuickFilter source="status" label="Active" defaultValue="1" />
];

const ClientRegistrationListActions: React.FC = () => (
	<TopToolbar>
		<FilterButton />
		<SelectColumnsButton />
	</TopToolbar>
);

export const ClientRegistrationList: React.FC = () => (
	<List
		filters={clientRegistrationFilters}
		exporter={false}
		actions={<ClientRegistrationListActions />}
	>
		<DatagridConfigurable rowClick="edit">
			<TextField source="client_id" label="ID" />
			<TextField source="client.practice_name" label="Practice Name" />
			<FunctionField
				label="A2P State"
				render={(a2pRecord: Schema.ClientRegistration) => {
					let state = 'Unrecognized State'; // default value
					if (a2pRecord.note === 'ON FLEX MSG SVC') {
						state = 'ON FLEX MSG SVC';
					} else if (a2pRecord.note === 'ASH MANUAL 073123') {
						state = 'ASH MANUAL 073123';
					} else if (
						a2pRecord.status &&
						[
							'g1',
							'g2',
							'g3',
							'g4',
							'g5',
							'g6',
							'MIGRATION: Pending new A2P data'
						].includes(a2pRecord.status)
					) {
						state = 'George Bucket';
					} else if (
						a2pRecord.status &&
						[
							'Pending A2P Brand Approval',
							'Pending A2P Campaign Approval',
							'retry',
							'retry error',
							'LACKING'
						].includes(a2pRecord.status)
					) {
						state = a2pRecord.status;
					} else if (
						a2pRecord.status &&
						[
							'SKIP',
							'SKIP (DO NOT MIGRATE)',
							'FINISHED (DO NOT MIGRATE)',
							'OFFBOARDED'
						].includes(a2pRecord.status)
					) {
						state = 'Non-concern';
					} else if (
						a2pRecord.status === 'FINISHED' &&
						a2pRecord.next === -10
					) {
						state = 'Legacy';
					} else if (
						a2pRecord.status === 'FINISHED' &&
						a2pRecord.next === -13
					) {
						state = 'Legacy (Weird)';
					} else if (
						(a2pRecord.status === 'FINISHED' &&
							a2pRecord.next !== -10) ||
						a2pRecord.status === 'MANUAL SUCCESS'
					) {
						state = 'Finished';
					} else if (a2pRecord.status === 'MANUAL FAIL') {
						state = 'Rejected (Manual)';
					} else if (
						a2pRecord.status === 'error' &&
						a2pRecord.has_error === -9
					) {
						state = 'Rejected (Brand)';
					} else if (
						a2pRecord.status === 'error' &&
						a2pRecord.has_error === -8
					) {
						state = 'Rejected (Campaign)';
					} else if (
						a2pRecord.status === 'error' &&
						a2pRecord.status_context &&
						a2pRecord.status_context.details &&
						a2pRecord.status_context.details[0] !== null
					) {
						state = 'Rejected (Flex)';
					}
					return state;
				}}
			/>
			<FunctionField
				label="Brand"
				render={(a2pRecord: Schema.ClientRegistration) => (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`https://console.twilio.com/us1/develop/sms/regulatory-compliance/brands/${a2pRecord.objects?.a2p_brand?.sid}`}
					>
						{a2pRecord.objects?.a2p_brand?.sid}
					</a>
				)}
			/>
			<TextField label="untrustworthy status" source="status" />
			<TextField source="note" />
			<NumberField source="has_error" />
			<NumberField source="next" />
		</DatagridConfigurable>
	</List>
);
