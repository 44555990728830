import { useCallback } from 'react';
import { Alert, AlertProps, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type ResultSnackbarProps = {
	message: string;
	open?: boolean;
	severity?: AlertProps['severity'];
	handleClose: () => void;
};

export const ResultSnackbar: React.FC<ResultSnackbarProps> = ({
	message,
	open,
	handleClose,
	severity = 'success'
}) => {
	const onClose = useCallback(
		(_event: React.SyntheticEvent | Event, reason?: string) => {
			if (reason === 'clickaway') {
				return;
			}

			handleClose();
		},
		[handleClose]
	);

	const action = (
		<IconButton size="small" color="inherit" onClick={onClose}>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
			action={action}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<Alert sx={{ width: '100%' }} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	);
};
