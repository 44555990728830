export const merchantOptions = [
	{
		name: 'Apex',
		value: 'apex'
	},
	{
		name: 'Card Connect',
		value: 'cardconnect'
	},
	{ name: 'Moolah', value: 'moolah' }
] as const;

export type MerchantOptionProps = (typeof merchantOptions)[number];

export type MerchantOption = MerchantOptionProps['value'];

export type FieldProps = {
	id: FieldName;
	label: string;
};

export type MerchantOptionFieldPropsMap = {
	[K in MerchantOption]: FieldProps[];
};

export type FieldState = {
	value: string;
	touched: boolean;
	dirty: boolean;
};

export type FieldName = 'user' | 'password' | 'mid' | 'key';

export type FieldValuePayload = {
	field: FieldName;
	value: string;
};

export type FieldsState = {
	[K in FieldName]: FieldState;
};

export type State = {
	merchant: MerchantOption;
	data: FieldsState;
	loading: boolean;
	success: boolean;
	successMessage: string;
	open: boolean;
	error?: string;
};

export type SetMerchantAction = {
	type: 'SET_MERCHANT';
	payload: MerchantOption;
};

export type ChangeAction = {
	type: 'CHANGE';
	payload: FieldValuePayload;
};

export type BlurAction = {
	type: 'BLUR';
	payload: FieldName;
};

export type OpenAction = {
	type: 'OPEN';
};

export type CloseAction = {
	type: 'CLOSE';
};

export type SubmitAction = {
	type: 'SUBMIT';
};

export type SuccessAction = {
	type: 'SUCCESS';
	payload: boolean;
};

export type SubmitSuccessAction = {
	type: 'SUBMIT_SUCCESS';
	payload: {
		isChange?: boolean;
	};
};

export type ErrorAction = {
	type: 'ERROR';
	payload: string;
};

export type Action =
	| SetMerchantAction
	| ChangeAction
	| BlurAction
	| OpenAction
	| CloseAction
	| SubmitAction
	| SuccessAction
	| SubmitSuccessAction
	| ErrorAction;
