import { useCallback } from 'react';
import { useGetRecordId, useRecordContext } from 'react-admin';

import { ConfirmationDialogButton } from '../../../../../components';
import { ButtonPropsNoClick, Schema } from '../../../../../types';

export const DownloadDatabaseButton: React.FC<ButtonPropsNoClick> = props => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	const onConfirm = useCallback(async () => {
		if (!record?.client_identifier) return;

		try {
			const res = await fetch(
				`/secure/downloadClientDB?client_identifier=${record.client_identifier}`
			);

			if (res.status === 409)
				throw new Error(
					'Database is currently downloading. Please wait.'
				);

			if (!res.ok)
				throw new Error(
					`Could not download database. Did you already fetch it? If not, please do so. Otherwise, we are experiencing an error. Error: ${res.status}: ${res.statusText}`
				);

			const url = window.URL.createObjectURL(await res.blob());
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${record.client_identifier}.db`);
			link.click();
			window.URL.revokeObjectURL(url);

			return { success: true };
		} catch (err) {
			let message = String(err);

			if (err instanceof Error) message = err.message;

			return { success: false, error: message };
		}
	}, [record?.client_identifier]);

	return (
		<ConfirmationDialogButton
			onConfirm={onConfirm}
			label="Download Database"
			message="Start client database download? Please be sure to fetch it first."
			successMessage={`Database download started for client ${id}.`}
			buttonProps={props}
		/>
	);
};
