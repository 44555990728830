import { useCallback, useMemo, useReducer } from 'react';
import { useGetRecordId, useRefresh } from 'react-admin';

import { FieldState, FieldProps, State, Action } from './types';
import { validate } from './validate';
import { merchantFieldPropsMap } from './constants';

const fieldDefault: Readonly<FieldState> = {
	dirty: false,
	touched: false,
	value: ''
};
const merchantDefaults: Readonly<State> = {
	merchant: 'apex',
	data: {
		user: fieldDefault,
		password: fieldDefault,
		mid: fieldDefault,
		key: fieldDefault
	},
	loading: false,
	success: false,
	successMessage: '',
	open: false
} as const;

const createFieldDefault = (value = ''): FieldState => ({
	...fieldDefault,
	value
});

const reducer = (state: Readonly<State>, action: Action): State => {
	switch (action.type) {
		case 'SET_MERCHANT':
			if (action.payload === 'moolah') {
				return {
					...state,
					merchant: 'moolah',
					data: {
						...merchantDefaults.data,
						user: createFieldDefault('moolah'),
						password: createFieldDefault('moolah'),
						mid: createFieldDefault('moolah')
					}
				};
			}

			return {
				...state,
				// update merchant
				merchant: action.payload,
				// reset fields
				data: { ...merchantDefaults.data }
			};
		case 'CHANGE':
			return {
				...state,
				data: {
					...state.data,
					[action.payload.field]: {
						...state.data[action.payload.field],
						value: action.payload.value,
						dirty: true
					}
				}
			};
		case 'BLUR':
			return {
				...state,
				data: {
					...state.data,
					[action.payload]: {
						...state.data[action.payload],
						touched: state.data[action.payload].dirty
					}
				}
			};
		case 'OPEN':
			return { ...merchantDefaults, open: true };
		case 'CLOSE':
			return { ...merchantDefaults, success: state.success, open: false };
		case 'SUBMIT':
			return {
				...state,
				error: undefined,
				loading: true
			};
		case 'SUBMIT_SUCCESS':
			return {
				...state,
				open: false,
				loading: false,
				success: true,
				successMessage: action.payload.isChange
					? 'FlexPay updated.'
					: 'FlexPay added.'
			};
		case 'SUCCESS':
			return {
				...state,
				success: action.payload
			};
		case 'ERROR':
			return {
				...state,
				loading: false,
				error: action.payload
			};
	}
};

type UseFlexPayButton = (isChange?: boolean) => {
	handleSave: () => Promise<void>;
	canSave: boolean;
	merchantFields: FieldProps[];
	state: Readonly<State>;
	dispatch: React.Dispatch<Action>;
};

export const useFlexPayButton: UseFlexPayButton = isChange => {
	const id = useGetRecordId();
	const refresh = useRefresh();
	const [state, dispatch] = useReducer(reducer, merchantDefaults);

	const anyValidationError = useMemo(
		() =>
			merchantFieldPropsMap[state.merchant]
				.map(({ id }) => validate(state.data[id].value))
				.some(errors => errors.length > 0),
		[state.data, state.merchant]
	);

	const canSave = useMemo(
		() => !anyValidationError && !state.loading,
		[anyValidationError, state.loading]
	);

	const merchantFields = useMemo(
		() => merchantFieldPropsMap[state.merchant],
		[state.merchant]
	);

	const handleSave = useCallback(async () => {
		if (anyValidationError) return;

		dispatch({ type: 'SUBMIT' });

		try {
			const body = {
				clientId: id,
				merchant: state.merchant,
				merchantKey: state.data.key.value,
				merchantUser: state.data.user.value,
				merchantPassword: state.data.password.value,
				merchantMid: state.data.mid.value,
				change: true
			};

			const res = await fetch('/secure/addFlexPay', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify(body)
			});

			if (!res.ok) {
				throw new Error(`Error: ${res.status} ${res.statusText}`);
			}

			dispatch({ type: 'SUBMIT_SUCCESS', payload: { isChange } });
			refresh();
		} catch (err) {
			let message = String(err);
			if (err instanceof Error) message = err.message;

			dispatch({ type: 'ERROR', payload: message });
		}
	}, [
		id,
		refresh,
		state.merchant,
		state.data.key.value,
		state.data.mid.value,
		state.data.password.value,
		state.data.user.value,
		anyValidationError,
		isChange
	]);

	return { handleSave, canSave, merchantFields, state, dispatch };
};
