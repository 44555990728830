import React, { useState, useEffect } from 'react';
import { usePermissions } from 'react-admin';
import { prettyPrintJson } from 'pretty-print-json';
import { TextField, Button } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';
import moment from 'moment';

const apiEntityCategories = [
	{
		name: 'Flex Attributes',
		entities: 'flex_client_attrs'
	},
	{
		name: 'A2P Brand',
		entities: 'a2p_brand'
	},
	{
		name: 'Messaging Service',
		entities: 'messaging_service'
	},
	{
		name: 'Campaign',
		entities: 'a2p_messaging_campaign_use_case'
	}
];

async function getRecord(id) {
	const record = await fetch(`/secure/a2p/record?id=${id}`);
	const results = await record.json();
	return results;
}

function getFieldLabel(field) {
	switch (field) {
		case 'business_name':
			return 'Legal Business Name';
		case 'business_registration_number':
			return 'Tax EIN';
		default:
			return field;
	}
}

export default function RecordDetails({ record_id }) {
	const { permissions } = usePermissions();
	const hasPermission = permission => permissions?.includes(permission);
	const [activeObj, setActiveObj] = useState('flex_client_attrs');
	const [open, setOpen] = useState({
		'Customer Profile': false,
		'A2P Bundle': false,
		'A2P Brand': false
	});
	const [record, setRecord] = useState({});
	const [openSnack, setOpenSnack] = useState(false);
	const [overrides, setOverrides] = useState(record.overrides || {});
	const [showOverrides, setShowOverrides] = useState(
		record.status === 'error'
	);

	const msgService = record?.objects?.messaging_service?.sid;
	const msgServiceURL = `https://console.twilio.com/us1/service/sms/${msgService}/messaging-service-compliance?frameUrl=%2Fconsole%2Fsms%2Fservices%2F${msgService}%2Fcompliance%3Fx-target-region%3Dus1`;

	const a2pBrand = record?.objects?.a2p_brand?.sid;
	const a2pBrandURL = `https://console.twilio.com/us1/develop/sms/regulatory-compliance/brands/${a2pBrand}`;

	useEffect(() => {
		getRecord(record_id).then(setRecord);
	}, [record_id]);

	useEffect(() => {
		setOverrides(record.overrides || {});
	}, [record]);

	const handleClick = artifact => {
		setOpen({
			...open,
			[artifact]: !open[artifact]
		});
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnack(false);
	};

	const toggleOverrides = () => {
		setShowOverrides(!showOverrides);
	};

	const overrideFields = [
		'business_name',
		'business_registration_number',
		'address',
		'state',
		'zip',
		'city',
		'email',
		'phone',
		'main_contact',
		'practice_name',
		'website_url'
	];

	const handleOverrides = event => {
		setOverrides({
			...overrides,
			[event.target.name]: event.target.value
		});
	};

	const saveOverrides = async () => {
		if (record.status && !['error', 'retry'].includes(record.status)) {
			const adminPw = prompt(
				'This record is not in an error or retry state. If you are absolutely certain that you want to override, contact an admin or enter the admin password'
			);
			if (adminPw !== process.env.REACT_APP_ADMIN_PW) {
				alert('Invalid password');
				return;
			}
		}
		// Validate EIN
		const ein = overrides.business_registration_number;
		if (ein && ein.toString().replace(/[^0-9a-z]/gi, '').length !== 9) {
			alert('EIN must be 9 digits');
			return;
		}

		await fetch('/secure/a2p/updateOverrides', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				client_id: record.client_id,
				overrides: {
					...overrides,
					last_updated: moment().format('MM/DD/YY HH:mm:ss')
				}
			})
		});
		setOpenSnack(true);
		setTimeout(function () {
			setOpenSnack(false);
		}, 3000);

		// Refetch after saving
		await getRecord(record_id)
			.then(setRecord)
			.catch(err => {
				console.error(err);
			});
	};

	return !record.client_id ? (
		<div>Loading</div>
	) : (
		<div className="record-details__inner">
			<div className="record-details__left active-record">
				<div className="attributes">
					<div className="attribute">
						<div className="field-label">Client ID</div>
						<div className="field-value">{record.client_id}</div>
					</div>
					<div className="attribute">
						<div className="field-label">Practice Name</div>
						<div className="field-value">
							{record?.practice_name}
						</div>
					</div>
					<div className="attribute">
						<div className="field-label">Main Contact</div>
						<div className="field-value">
							{record?.main_contact}
						</div>
					</div>
					<div className="attribute">
						<div className="field-label">Status</div>
						<div className="field-value">{record.status}</div>
					</div>
					---
					<div className="attribute stacked">
						<div className="field-label">Legal Name</div>
						<div className="field-value">
							{record.objects?.flex_client_attrs?.business_info
								?.business_name ?? 'N/A'}
						</div>
					</div>
					<div className="attribute stacked">
						<div className="field-label">EIN</div>
						<div className="field-value">
							{record.objects?.flex_client_attrs?.business_info
								?.business_registration_number ?? 'N/A'}
						</div>
					</div>
					{a2pBrand && hasPermission('Access Brand URL') && (
						<div className="attribute stacked">
							<div className="field-label">Brand</div>
							<div className="field-value">
								<a href={`${a2pBrandURL}`} target="_blank">
									🔗 Brand
								</a>
							</div>
						</div>
					)}
					{msgService && hasPermission('Access Msg Service URL') && (
						<div className="attribute stacked">
							<div className="field-label">Msg Service</div>
							<div className="field-value">
								<a href={`${msgServiceURL}`} target="_blank">
									🔗 Msg Service
								</a>
							</div>
						</div>
					)}
				</div>

				<h5 style={{ marginTop: '.5rem' }}>Objects</h5>
				<div className="record-objects">
					<List>
						{record.objects &&
							apiEntityCategories.map(category =>
								Array.isArray(category.entities) ? (
									<div key={category.name}>
										<ListItemButton
											sx={{ py: 0, px: 1 }}
											onClick={() =>
												handleClick(category.name)
											}
										>
											<ListItemText
												primary={category.name}
											/>
											{open[category.name] ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemButton>
										<Collapse
											in={open[category.name]}
											timeout="auto"
											unmountOnExit
											key={`collapse-${category.name}`}
										>
											<List
												component="div"
												disablePadding
											>
												{category.entities &&
													category.entities.map(
														obj =>
															record.objects?.[
																obj
															] && (
																<ListItemButton
																	sx={{
																		py: 0,
																		pl: 3
																	}}
																	className={`object-selector ${
																		activeObj &&
																		activeObj ===
																			obj
																			? 'is-active-bg'
																			: ''
																	}`}
																	onClick={() =>
																		setActiveObj(
																			obj
																		)
																	}
																	key={`object-select${obj}`}
																>
																	<ListItemText
																		primary={
																			obj
																		}
																	/>
																</ListItemButton>
															)
													)}
											</List>
										</Collapse>
									</div>
								) : (
									<ListItemButton
										sx={{ py: 0, px: 1 }}
										className={`object-selector ${
											activeObj &&
											activeObj === category.entities
												? 'is-active-bg'
												: ''
										}`}
										onClick={() =>
											setActiveObj(category.entities)
										}
										key={`object-select${category.entities}`}
									>
										<ListItemText primary={category.name} />
									</ListItemButton>
								)
							)}
					</List>
				</div>
			</div>
			<div className="record-details__right">
				{record.status_context && record.status === 'error' && (
					<div className="status-context bg-red">
						{record.status_context.details ? (
							<>
								<div>Validation errors</div>
								<div>
									<div>
										{JSON.stringify(
											record.status_context.summary ?? ''
										)}
									</div>
									<div>
										{JSON.stringify(
											record.status_context.details
										)}
									</div>
									{record.status_context.details?.[0]
										.summary ===
									'Rejected at A2P Brand TCR Review' ? (
										<div>
											<div>
												Brand Feedback:
												{record.objects?.a2p_brand?.brandFeedback?.join(
													', '
												)}
											</div>
											<div>
												Failure Reason:
												{
													record.objects?.a2p_brand
														?.failureReason
												}
											</div>
										</div>
									) : (
										''
									)}
								</div>
							</>
						) : (
							<>
								<div>Twilio error</div>
								<div>
									<a
										target="_blank"
										href={record.status_context.moreInfo}
									>
										{record.status_context.moreInfo}
									</a>
								</div>
							</>
						)}
					</div>
				)}
				{showOverrides && hasPermission('A2P Data Overrides') && (
					<div className="record-overrides">
						<h5>Overrides</h5>
						<div className="record-overrides__inner">
							<div className="last-updated-on">
								Last updated{' '}
								{moment(overrides.last_updated).fromNow()}
							</div>
							{overrideFields.map(field => (
								<TextField
									label={getFieldLabel(field)}
									variant="outlined"
									name={field}
									size="small"
									value={overrides[field] || ''}
									onChange={handleOverrides}
									key={field}
								/>
							))}{' '}
						</div>
						<Button
							sx={{ float: 'right' }}
							variant="contained"
							onClick={saveOverrides}
						>
							Save
						</Button>
					</div>
				)}

				{activeObj && record.objects && (
					<pre
						dangerouslySetInnerHTML={{
							__html: prettyPrintJson.toHtml(
								record.objects?.[activeObj]
							)
						}}
					></pre>
				)}
			</div>
			<Fab
				sx={{
					position: 'fixed',
					bottom: '1rem',
					right: '1rem'
				}}
				variant="extended"
				color="primary"
				/* on click, toggle showOverrides */
				onClick={toggleOverrides}
			>
				Overrides
			</Fab>
			<Snackbar
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleClose}
				message="Note archived"
				severity="success"
			/>
		</div>
	);
}
