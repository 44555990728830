// import { EditGuesser, ListGuesser } from 'react-admin';
import {
	Admin,
	Resource,
	defaultTheme,
	useLogin,
	CustomRoutes,
	CREATE,
	UPDATE,
	DELETE
} from 'react-admin';
import { useDataProvider } from '@ra-data-prisma/dataprovider';
import { Button, CircularProgress } from '@mui/material';
import { BrowserRouter, Navigate, Route } from 'react-router-dom';
import gql from 'graphql-tag';

import * as resources from './resources';
import { clientAuthSearch } from './resources/ClientAuth/lib';
import { authProvider } from './authProvider';

import schema from './generated/graphql/introspection.json';
import { Schema } from './types';
import { clientRegistrationSearch } from './resources/ClientRegistration/lib/clientRegistrationSearch';
import { clientGroupSearch } from './resources/ClientGroup/lib/ClientGroupSearch';

const theme = {
	...defaultTheme,
	components: {
		...defaultTheme.components,
		MuiTextField: {
			...defaultTheme.components?.MuiTextField,
			defaultProps: {
				...defaultTheme.components?.MuiTextField.defaultProps,
				variant: 'standard'
			}
		},
		RaLabeled: {
			styleOverrides: {
				root: {
					'& .MuiTypography-body2': {
						overflowWrap: 'anywhere'
					}
				}
			}
		}
	}
} as const;

const LoginPage: React.FC = () => {
	const login = useLogin();

	return (
		<Button variant="contained" onClick={() => login({})}>
			Login
		</Button>
	);
};

export const App: React.FC = () => {
	const dataProvider = useDataProvider({
		introspection: { schema: schema.__schema },
		clientOptions: { uri: '/secure/graphql' },
		queryDialect: 'typegraphql',
		filters: {
			clientAuth_subscriptionid: (
				value?: string
			): Schema.ClientAuthWhereInput => {
				console.log('clientAuth_subscription_id');
				console.log('clientAuth_subscription_id: value', value);
				if (!value) return {};

				value = value.trim();

				if (value === '') return {};

				const valueAsNumber = Number(value);
				if (isNaN(valueAsNumber)) return {};

				return {
					client: {
						is: {
							subscription_id: {
								equals: valueAsNumber
							}
						}
					}
				};
			},
			clientAuth_install_complete: (
				value: boolean
			): Schema.ClientAuthWhereInput => ({
				client: {
					is: {
						install_complete: {
							equals: Boolean(value)
						}
					}
				}
			}),
			clientAuth_search: clientAuthSearch,
			clientRegistration_search: clientRegistrationSearch,
			clientGroup_search: clientGroupSearch
		},
		mutationOperationNames: {
			typegraphql: {
				[CREATE]: resource => `createOne${resource.name}`,
				[UPDATE]: resource => `updateOne${resource.name}`,
				[DELETE]: resource => `deleteOne${resource.name}`
			}
		},
		resourceViews: {
			DisconnectedClient: {
				resource: 'ClientAuth',
				fragment: {
					many: {
						type: 'document',
						doc: gql`
							fragment DisconnectedClient on ClientAuth {
								__typename
								id
								parent_identifier
								client_identifier
								status
								isAdminConnected
								isFlexConnected
								version
								target_version
								last_auth
								client {
									__typename
									id
									practice_name
									subscription_id
									installedFlexPackage
									install_complete
								}
							}
						`
					}
				}
			}
		}
	});

	if (!dataProvider) {
		return <CircularProgress />;
	}

	return (
		<BrowserRouter>
			<Admin
				loginPage={LoginPage}
				dataProvider={dataProvider}
				authProvider={authProvider}
				theme={theme}
			>
				<CustomRoutes>
					<Route
						path="/dashboard"
						element={<Navigate to="/ClientAuth" />}
					/>
				</CustomRoutes>

				<Resource
					name="ClientAuth"
					options={{ label: 'Clients' }}
					recordRepresentation="client.practice_name"
					list={resources.ClientAuth.ClientAuthList}
					edit={resources.ClientAuth.ClientAuthEdit}
				/>
				<Resource
					name="DisconnectedClient"
					options={{ label: 'Disconnected Clients' }}
					recordRepresentation="client.practice_name"
					list={resources.DisconnectedClient.DisconnectedClientList}
				/>
				<Resource
					name="ClientGroup"
					options={{ label: 'Client Groups' }}
					recordRepresentation="name"
					list={resources.ClientGroup.ClientGroupList}
					edit={resources.ClientGroup.ClientGroupEdit}
					create={resources.ClientGroup.ClientGroupCreate}
				/>
				<Resource
					name="FeatureFlag"
					options={{ label: 'Feature Flags' }}
					list={resources.FeatureFlag.FeatureFlagList}
					edit={resources.FeatureFlag.FeatureFlagEdit}
					create={resources.FeatureFlag.FeatureFlagCreate}
				/>
				<Resource
					name="ClientRegistration"
					options={{ label: 'A2P Records' }}
					list={resources.ClientRegistration.ClientRegistrationList}
					edit={resources.ClientRegistration.ClientRegistrationEdit}
					// create={resources.FeatureFlag.FeatureFlagCreate}
				/>
			</Admin>
		</BrowserRouter>
	);
};
