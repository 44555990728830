import { useCallback } from 'react';
import { useGetRecordId, useRecordContext } from 'react-admin';

import { ConfirmationDialogButton } from '../../../../../components';
import { ButtonPropsNoClick, Schema } from '../../../../../types';

type DownloadAssetFromS3ButtonProps = ButtonPropsNoClick & {
	children: string;
	assetType: 'DB' | 'LOG_ARCHIVE';
};

export const DownloadAssetFromS3Button: React.FC<
	DownloadAssetFromS3ButtonProps
> = ({ children, assetType, ...props }) => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	let message = '';
	let successMessage = '';
	switch (assetType) {
		case 'DB':
			message = `Download database archive?`;
			successMessage = `Database download started for client ${id}.`;
			break;
		case 'LOG_ARCHIVE':
			message = `Download client log archive?`;
			successMessage = `Log archive download started for client ${id}.`;
			break;
	}

	const onConfirm = useCallback(async () => {
		if (!record?.client_identifier) return;

		try {
			const endpointURL = new URL(
				'/secure/getLinkToClientFile',
				window.location.origin
			);
			endpointURL.searchParams.set(
				'client_identifier',
				record.client_identifier
			);
			endpointURL.searchParams.set('fileType', assetType);
			const res = await fetch(endpointURL.toString());

			if (!res.ok)
				throw new Error(
					`Could not download file. Error: ${res.status}: ${res.statusText}`
				);

			const { signedUrl }: { signedUrl: string } = await res.json();
			const link = document.createElement('a');
			link.href = signedUrl;
			link.click();
			window.URL.revokeObjectURL(signedUrl);

			return { success: true };
		} catch (err) {
			let message = String(err);

			if (err instanceof Error) message = err.message;

			return { success: false, error: message };
		}
	}, [record?.client_identifier, assetType]);

	return (
		<ConfirmationDialogButton
			onConfirm={onConfirm}
			label={children}
			message={message}
			successMessage={successMessage}
			buttonProps={props}
		/>
	);
};
