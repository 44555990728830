import {
	Edit,
	SimpleForm,
	TextInput,
	TextField,
	Labeled,
	usePermissions
} from 'react-admin';

import { ClientAuthReferenceArrayInput } from '../ClientAuth/components';
import { FeatureFlagReferenceArrayInput } from '../FeatureFlag/FeatureFlagReferenceArrayInput';
import { PrivilegedToolbar } from '../../components/PrivilegedToolbar';

export const ClientGroupEdit: React.FC = () => {
	const { permissions } = usePermissions<string[]>();
	const hasPermission = (permission: string) =>
		permissions?.includes(permission);
	const disableDelete = !hasPermission('Delete Client Group');

	return (
		<Edit>
			<SimpleForm
				toolbar={
					<PrivilegedToolbar
						disableSave={false}
						disableDelete={disableDelete}
					/>
				}
			>
				<Labeled>
					<TextField source="id" />
				</Labeled>

				<TextInput
					source="name"
					disabled={
						!hasPermission('Modify Client Group or Feature Flag')
					}
				/>
				<TextInput
					source="description"
					disabled={
						!hasPermission('Modify Client Group or Feature Flag')
					}
				/>
				<ClientAuthReferenceArrayInput
					disabled={!hasPermission('Add Clients to Client Group')}
					source="clients_ids"
				/>
				<FeatureFlagReferenceArrayInput source="features_ids" />
			</SimpleForm>
		</Edit>
	);
};
