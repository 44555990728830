import { useCallback, useMemo, useState } from 'react';
import {
	Alert,
	Button,
	DialogContentText,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Stack,
	CircularProgress
} from '@mui/material';
import { useGetRecordId, useRecordContext, useRefresh } from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';

import { DialogButton, DialogButtonProps } from '../../../../../components';
import { Schema } from '../../../../../types';

export type GetSMSNumberButtonProps = DialogButtonProps['buttonProps'] & {
	disabled?: boolean;
};

type Validation = {
	error: boolean;
	errors: string[];
};

const validateAreaCode = (value: string | null): Validation => {
	if (!value) {
		return {
			error: true,
			errors: ['Please enter an area code.']
		};
	}
	const errors: string[] = [];

	const valueAsNumber = Number(value);

	if (isNaN(valueAsNumber)) {
		errors.push('The area code can only contain digits.');
	}

	if (value.length !== 3) {
		errors.push('The area code must be exactly 3 digits long.');
	}

	return {
		error: errors.length > 0,
		errors
	};
};

type Capabilities = {
	voice: boolean;
	SMS: boolean;
	MMS: boolean;
};

type SMSNumber = {
	friendlyName: string;
	phoneNumber: string;
	lata: string;
	locality: string;
	rateCenter: string;
	latitude: number;
	longitude: number;
	region: string;
	postalCode: string;
	isoCountry: string;
	addressRequirements: string;
	beta: boolean;
	capabilities: Capabilities;
};

export const GetSMSNumberButton: React.FC<GetSMSNumberButtonProps> = ({
	disabled = true,
	...props
}) => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const refresh = useRefresh();
	const [numbers, setNumbers] = useState<SMSNumber[]>();
	const [selectedNumberIndex, setSelectedNumberIndex] = useState<number>();
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [touched, setTouched] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [value, setValue] = useState<string | null>(null);
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState(false);
	const validation = useMemo(() => validateAreaCode(value), [value]);
	const validationError = useMemo(
		() => touched && dirty && validation.error,
		[touched, dirty, validation.error]
	);

	const reset = useCallback(() => {
		setLoading(false);
		setNumbers(undefined);
		setSelectedNumberIndex(undefined);
		setValue(null);
		setError(undefined);
		setTouched(false);
		setDirty(false);
		setIsOpen(false);
		refresh();
	}, [refresh]);

	const handleGetNumbers = useCallback(async () => {
		if (validation.error) return;

		setError(undefined);
		setLoading(true);

		try {
			const res = await fetch(
				`/secure/getTwilioNumbers?areacode=${value}`
			);

			if (!res.ok) {
				throw new Error(`Error: ${res.status} ${res.statusText}`);
			}

			const body = await res.json();

			if (!body.phones || !body.phones.length) {
				setLoading(false);
				setError('No numbers with that area code could be located');
				return;
			}

			setNumbers(body.phones);
		} catch (err) {
			let message = String(err);
			if (err instanceof Error) message = err.message;

			setError(message);
		}

		setLoading(false);
	}, [validation.error, value]);

	const handleSave = useCallback(async () => {
		if (
			!record?.client.practice_name ||
			!numbers ||
			selectedNumberIndex === undefined
		)
			return;

		const number = numbers[selectedNumberIndex];

		if (!number) return;

		setLoading(true);

		try {
			const res = await fetch('/secure/createTwilioNumber', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify({
					clientId: id,
					practiceName: record.client.practice_name,
					number: number.phoneNumber
				})
			});

			if (!res.ok) {
				throw new Error(`Error: ${res.status} ${res.statusText}`);
			}

			setSuccess(true);
			reset();
		} catch (err) {
			let message = String(err);
			if (err instanceof Error) message = err.message;

			setLoading(false);
			setError(message);
		}
	}, [selectedNumberIndex, numbers, record?.client.practice_name, reset, id]);

	return (
		<DialogButton
			isOpen={isOpen}
			handleClose={reset}
			handleSave={handleSave}
			setIsOpen={setIsOpen}
			canSave={selectedNumberIndex !== undefined && !loading}
			label="Get SMS Number"
			buttonProps={props}
			success={success}
			setSuccess={setSuccess}
			successMessage="SMS number set."
			disabled={disabled}
		>
			<DialogContentText>
				Please enter an area code to search for available numbers
			</DialogContentText>

			<TextField
				fullWidth
				label="Area code"
				error={validationError}
				value={value}
				onBlur={() => {
					if (!touched && dirty) setTouched(true);
				}}
				onChange={e => {
					if (!dirty) setDirty(true);
					setValue(e.target.value);
				}}
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				helperText={
					validationError ? (
						<>
							{validation.errors.map(error => (
								<div>{error}</div>
							))}
						</>
					) : (
						''
					)
				}
			/>

			<Button
				startIcon={numbers ? <RefreshIcon /> : undefined}
				disabled={validation.error || loading}
				onClick={handleGetNumbers}
				variant="contained"
				sx={{ mt: 1, mb: 2 }}
			>
				Get Numbers
			</Button>

			<Stack spacing={2}>
				{numbers && (
					<FormControl>
						<RadioGroup
							row
							onChange={e =>
								setSelectedNumberIndex(Number(e.target.value))
							}
						>
							{numbers.map((number, index) => (
								<FormControlLabel
									value={index}
									control={<Radio />}
									label={number.friendlyName}
									key={index}
								/>
							))}
						</RadioGroup>
					</FormControl>
				)}
				{loading && <CircularProgress />}
				{error && <Alert severity="error">{error}</Alert>}
			</Stack>
		</DialogButton>
	);
};
