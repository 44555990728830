import { useEffect, useMemo, useState } from 'react';
import {
	useRecordContext,
	ListContextProvider,
	Pagination,
	useList
} from 'react-admin';

import { Schema } from '../../../types';

type LogData = {
	id: number;
	line: string;
};

export type ClientAuthLogTabProps = {
	type: 'out' | 'err';
};

export const ClientAuthLogTab: React.FC<ClientAuthLogTabProps> = ({ type }) => {
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	const [data, setData] = useState<LogData[]>([]);
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState(true);
	const listContext = useList({
		data,
		isLoading: loading,
		perPage: 25
	});

	const endpoint = useMemo(() => {
		switch (type) {
			case 'out':
				return 'getlog';
			case 'err':
				return 'getErrorLog';
		}
	}, [type]);

	const log = useMemo(
		() =>
			listContext.data
				.reverse()
				.map(({ id, line }) => `${id}: ${line}`)
				.join('\n'),
		[listContext.data]
	);

	useEffect(() => {
		if (!record) return;

		(async () => {
			try {
				const res = await fetch(
					`/secure/${endpoint}?client_identifier=${record.client_identifier}`
				);
				setLoading(false);

				if (res.status === 400) {
					return setError('Client not connected.');
				}

				if (!res.ok)
					return setError(
						`Error: ${res.status} ${
							res.statusText
						}: ${await res.text()}`
					);

				const rawLog = await res.text();
				const splitLog = rawLog.split('\n');
				const mappedLog = splitLog
					.map((line, index) => ({
						id: index,
						line
					}))
					.reverse();

				setData(mappedLog);
			} catch (err) {
				setError(String(err));
			}
		})();
	}, [record, endpoint]);

	if (error) return <>{error}</>;
	if (loading || data === undefined) return <>Loading...</>;

	return (
		<ListContextProvider value={listContext}>
			<>
				<pre style={{ maxWidth: '100%', overflow: 'auto' }}>{log}</pre>

				<Pagination
					rowsPerPageOptions={[10, 25, 50, 100, 200, 400, 800, 1600]}
				/>
			</>
		</ListContextProvider>
	);
};
