import { useMemo } from 'react';
import {
	Alert,
	DialogContentText,
	TextField,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';

import { DialogButton, DialogButtonProps } from '../../../../../../components';
import { Action, FieldName, FieldState, merchantOptions } from './types';
import { validate } from './validate';
import { useFlexPayButton } from './useFlexPayButton';

type FieldProps = {
	state: FieldState;
	id: FieldName;
	label: string;
	dispatch: React.Dispatch<Action>;
};

const Field: React.FC<FieldProps> = ({
	state: { touched, dirty, value },
	id,
	label,
	dispatch
}) => {
	const validation = useMemo(() => validate(value), [value]);
	const validationError = useMemo(
		() => touched && dirty && validation.length > 0,
		[dirty, touched, validation.length]
	);

	return (
		<TextField
			key={id}
			fullWidth
			label={label}
			error={validationError}
			value={value}
			onBlur={() => dispatch({ type: 'BLUR', payload: id })}
			onChange={e =>
				dispatch({
					type: 'CHANGE',
					payload: {
						field: id,
						value: e.target.value
					}
				})
			}
			helperText={
				validationError ? (
					<>
						{validation.map(error => (
							<div>{error}</div>
						))}
					</>
				) : (
					''
				)
			}
		/>
	);
};

export type FlexPayButtonProps = DialogButtonProps['buttonProps'] & {
	isChange?: boolean;
	disabled?: boolean;
};

export const FlexPayButton: React.FC<FlexPayButtonProps> = ({
	disabled = true,
	isChange,
	...props
}) => {
	const { handleSave, canSave, merchantFields, state, dispatch } =
		useFlexPayButton(isChange);

	return (
		<DialogButton
			isOpen={state.open}
			handleClose={() => dispatch({ type: 'CLOSE' })}
			handleSave={handleSave}
			setIsOpen={open => {
				let isOpen = Boolean(open);
				if (typeof open === 'function') isOpen = open(state.open);

				return isOpen
					? dispatch({ type: 'OPEN' })
					: dispatch({ type: 'CLOSE' });
			}}
			canSave={canSave}
			label={isChange ? 'Change FlexPay' : 'Add FlexPay'}
			buttonProps={props}
			dialogProps={{ maxWidth: 'sm' }}
			disabled={disabled}
			success={state.success}
			successMessage={state.successMessage}
			setSuccess={success =>
				dispatch({
					type: 'SUCCESS',
					payload:
						typeof success === 'function'
							? success(state.success)
							: success
				})
			}
		>
			<DialogContentText variant="subtitle1">
				Which Merchant?
			</DialogContentText>

			<ToggleButtonGroup
				value={state.merchant}
				exclusive
				onChange={(_event, newMerchant) => {
					if (!newMerchant) return;
					dispatch({ type: 'SET_MERCHANT', payload: newMerchant });
				}}
			>
				{merchantOptions.map(({ name, value }) => (
					<ToggleButton key={value} value={value}>
						{name}
					</ToggleButton>
				))}
			</ToggleButtonGroup>

			{merchantFields.map(({ id, label }) => (
				<Field
					key={id}
					id={id}
					label={label}
					state={state.data[id]}
					dispatch={dispatch}
				/>
			))}
			{state.error && <Alert severity="error">{state.error}</Alert>}
		</DialogButton>
	);
};
